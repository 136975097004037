import React from 'react';
import './App.css';
import NationalComponent from './components/NationalComponent';
function App() {
  return (
    <div className="App">
      
      <NationalComponent />
    </div>
  );
}

export default App;

import { Location } from './Location';

export const ThailandDatabase: Location[] = [
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'พระนคร',
        amphoe_code: 1001,
        zipcode: 10200,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'ดุสิต',
        amphoe_code: 1002,
        zipcode: 10300,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'หนองจอก',
        amphoe_code: 1003,
        zipcode: 10530,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'บางรัก',
        amphoe_code: 1004,
        zipcode: 10500,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'บางเขน',
        amphoe_code: 1005,
        zipcode: 10220,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'บางกะปิ',
        amphoe_code: 1006,
        zipcode: 10240,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'ปทุมวัน',
        amphoe_code: 1007,
        zipcode: 10330,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'ป้อมปราบศัตรูพ่าย',
        amphoe_code: 1008,
        zipcode: 10100,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'พระโขนง',
        amphoe_code: 1009,
        zipcode: 10260,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'มีนบุรี',
        amphoe_code: 1010,
        zipcode: 10510,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'ลาดกระบัง',
        amphoe_code: 1011,
        zipcode: 10520,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'ยานนาวา',
        amphoe_code: 1012,
        zipcode: 10120,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'สัมพันธวงศ์',
        amphoe_code: 1013,
        zipcode: 10100,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'พญาไท',
        amphoe_code: 1014,
        zipcode: 10400,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'ธนบุรี',
        amphoe_code: 1015,
        zipcode: 10600,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'บางกอกใหญ่',
        amphoe_code: 1016,
        zipcode: 10600,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'ห้วยขวาง',
        amphoe_code: 1017,
        zipcode: 10310,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'คลองสาน',
        amphoe_code: 1018,
        zipcode: 10600,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'ตลิ่งชัน',
        amphoe_code: 1019,
        zipcode: 10170,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'บางกอกน้อย',
        amphoe_code: 1020,
        zipcode: 10700,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'บางขุนเทียน',
        amphoe_code: 1021,
        zipcode: 10150,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'ภาษีเจริญ',
        amphoe_code: 1022,
        zipcode: 10160,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'หนองแขม',
        amphoe_code: 1023,
        zipcode: 10160,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'ราษฎร์บูรณะ',
        amphoe_code: 1024,
        zipcode: 10140,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'บางพลัด',
        amphoe_code: 1025,
        zipcode: 10700,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'ดินแดง',
        amphoe_code: 1026,
        zipcode: 10400,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'บึงกุ่ม',
        amphoe_code: 1027,
        zipcode: 10230,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'สาทร',
        amphoe_code: 1028,
        zipcode: 10120,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'บางซื่อ',
        amphoe_code: 1029,
        zipcode: 10800,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'จตุจักร',
        amphoe_code: 1030,
        zipcode: 10900,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'บางคอแหลม',
        amphoe_code: 1031,
        zipcode: 10120,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'ประเวศ',
        amphoe_code: 1032,
        zipcode: 10250,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'คลองเตย',
        amphoe_code: 1033,
        zipcode: 10110,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'สวนหลวง',
        amphoe_code: 1034,
        zipcode: 10250,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'จอมทอง',
        amphoe_code: 1035,
        zipcode: 10150,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'ดอนเมือง',
        amphoe_code: 1036,
        zipcode: 10210,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'ราชเทวี',
        amphoe_code: 1037,
        zipcode: 10400,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'ลาดพร้าว',
        amphoe_code: 1038,
        zipcode: 10230,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'วัฒนา',
        amphoe_code: 1039,
        zipcode: 10110,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'บางแค',
        amphoe_code: 1040,
        zipcode: 10160,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'หลักสี่',
        amphoe_code: 1041,
        zipcode: 10210,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'สายไหม',
        amphoe_code: 1042,
        zipcode: 10220,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'คันนายาว',
        amphoe_code: 1043,
        zipcode: 10230,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'สะพานสูง',
        amphoe_code: 1044,
        zipcode: 10240,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'วังทองหลาง',
        amphoe_code: 1045,
        zipcode: 10310,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'คลองสามวา',
        amphoe_code: 1046,
        zipcode: 10510,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'บางนา',
        amphoe_code: 1047,
        zipcode: 10260,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'ทวีวัฒนา',
        amphoe_code: 1048,
        zipcode: 10170,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'ทุ่งครุ',
        amphoe_code: 1049,
        zipcode: 10140,
    },
    {
        province: 'กรุงเทพมหานคร',
        province_code: 10,
        amphoe: 'บางบอน',
        amphoe_code: 1050,
        zipcode: 10150,
    },
    {
        province: 'สมุทรปราการ',
        province_code: 11,
        amphoe: 'เมืองสมุทรปราการ',
        amphoe_code: 1101,
        zipcode: 10280,
    },
    {
        province: 'สมุทรปราการ',
        province_code: 11,
        amphoe: 'บางบ่อ',
        amphoe_code: 1102,
        zipcode: 10550,
    },
    {
        province: 'สมุทรปราการ',
        province_code: 11,
        amphoe: 'บางพลี',
        amphoe_code: 1103,
        zipcode: 10540,
    },
    {
        province: 'สมุทรปราการ',
        province_code: 11,
        amphoe: 'พระประแดง',
        amphoe_code: 1104,
        zipcode: 10130,
    },
    {
        province: 'สมุทรปราการ',
        province_code: 11,
        amphoe: 'พระสมุทรเจดีย์',
        amphoe_code: 1105,
        zipcode: 10290,
    },
    {
        province: 'สมุทรปราการ',
        province_code: 11,
        amphoe: 'บางเสาธง',
        amphoe_code: 1106,
        zipcode: 10570,
    },
    {
        province: 'นนทบุรี',
        province_code: 12,
        amphoe: 'เมืองนนทบุรี',
        amphoe_code: 1201,
        zipcode: 11000,
    },
    {
        province: 'นนทบุรี',
        province_code: 12,
        amphoe: 'บางกรวย',
        amphoe_code: 1202,
        zipcode: 11130,
    },
    {
        province: 'นนทบุรี',
        province_code: 12,
        amphoe: 'บางใหญ่',
        amphoe_code: 1203,
        zipcode: 11140,
    },
    {
        province: 'นนทบุรี',
        province_code: 12,
        amphoe: 'บางบัวทอง',
        amphoe_code: 1204,
        zipcode: 11110,
    },
    {
        province: 'นนทบุรี',
        province_code: 12,
        amphoe: 'ไทรน้อย',
        amphoe_code: 1205,
        zipcode: 11150,
    },
    {
        province: 'นนทบุรี',
        province_code: 12,
        amphoe: 'ปากเกร็ด',
        amphoe_code: 1206,
        zipcode: 11120,
    },
    {
        province: 'ปทุมธานี',
        province_code: 13,
        amphoe: 'เมืองปทุมธานี',
        amphoe_code: 1301,
        zipcode: 12000,
    },
    {
        province: 'ปทุมธานี',
        province_code: 13,
        amphoe: 'คลองหลวง',
        amphoe_code: 1302,
        zipcode: 12120,
    },
    {
        province: 'ปทุมธานี',
        province_code: 13,
        amphoe: 'ธัญบุรี',
        amphoe_code: 1303,
        zipcode: 12110,
    },
    {
        province: 'ปทุมธานี',
        province_code: 13,
        amphoe: 'หนองเสือ',
        amphoe_code: 1304,
        zipcode: 12170,
    },
    {
        province: 'ปทุมธานี',
        province_code: 13,
        amphoe: 'ลาดหลุมแก้ว',
        amphoe_code: 1305,
        zipcode: 12140,
    },
    {
        province: 'ปทุมธานี',
        province_code: 13,
        amphoe: 'ลำลูกกา',
        amphoe_code: 1306,
        zipcode: 12130,
    },
    {
        province: 'ปทุมธานี',
        province_code: 13,
        amphoe: 'สามโคก',
        amphoe_code: 1307,
        zipcode: 12160,
    },
    {
        province: 'พระนครศรีอยุธยา',
        province_code: 14,
        amphoe: 'พระนครศรีอยุธยา',
        amphoe_code: 1401,
        zipcode: 13000,
    },
    {
        province: 'พระนครศรีอยุธยา',
        province_code: 14,
        amphoe: 'ท่าเรือ',
        amphoe_code: 1402,
        zipcode: 13130,
    },
    {
        province: 'พระนครศรีอยุธยา',
        province_code: 14,
        amphoe: 'นครหลวง',
        amphoe_code: 1403,
        zipcode: 13260,
    },
    {
        province: 'พระนครศรีอยุธยา',
        province_code: 14,
        amphoe: 'บางไทร',
        amphoe_code: 1404,
        zipcode: 13190,
    },
    {
        province: 'พระนครศรีอยุธยา',
        province_code: 14,
        amphoe: 'บางบาล',
        amphoe_code: 1405,
        zipcode: 13250,
    },
    {
        province: 'พระนครศรีอยุธยา',
        province_code: 14,
        amphoe: 'บางปะอิน',
        amphoe_code: 1406,
        zipcode: 13160,
    },
    {
        province: 'พระนครศรีอยุธยา',
        province_code: 14,
        amphoe: 'บางปะหัน',
        amphoe_code: 1407,
        zipcode: 13220,
    },
    {
        province: 'พระนครศรีอยุธยา',
        province_code: 14,
        amphoe: 'ผักไห่',
        amphoe_code: 1408,
        zipcode: 13120,
    },
    {
        province: 'พระนครศรีอยุธยา',
        province_code: 14,
        amphoe: 'ภาชี',
        amphoe_code: 1409,
        zipcode: 13140,
    },
    {
        province: 'พระนครศรีอยุธยา',
        province_code: 14,
        amphoe: 'ลาดบัวหลวง',
        amphoe_code: 1410,
        zipcode: 13230,
    },
    {
        province: 'พระนครศรีอยุธยา',
        province_code: 14,
        amphoe: 'วังน้อย',
        amphoe_code: 1411,
        zipcode: 13170,
    },
    {
        province: 'พระนครศรีอยุธยา',
        province_code: 14,
        amphoe: 'เสนา',
        amphoe_code: 1412,
        zipcode: 13110,
    },
    {
        province: 'พระนครศรีอยุธยา',
        province_code: 14,
        amphoe: 'บางซ้าย',
        amphoe_code: 1413,
        zipcode: 13270,
    },
    {
        province: 'พระนครศรีอยุธยา',
        province_code: 14,
        amphoe: 'อุทัย',
        amphoe_code: 1414,
        zipcode: 13210,
    },
    {
        province: 'พระนครศรีอยุธยา',
        province_code: 14,
        amphoe: 'มหาราช',
        amphoe_code: 1415,
        zipcode: 13150,
    },
    {
        province: 'พระนครศรีอยุธยา',
        province_code: 14,
        amphoe: 'บ้านแพรก',
        amphoe_code: 1416,
        zipcode: 13240,
    },
    {
        province: 'อ่างทอง',
        province_code: 15,
        amphoe: 'เมืองอ่างทอง',
        amphoe_code: 1501,
        zipcode: 14000,
    },
    {
        province: 'อ่างทอง',
        province_code: 15,
        amphoe: 'ไชโย',
        amphoe_code: 1502,
        zipcode: 14140,
    },
    {
        province: 'อ่างทอง',
        province_code: 15,
        amphoe: 'ป่าโมก',
        amphoe_code: 1503,
        zipcode: 14130,
    },
    {
        province: 'อ่างทอง',
        province_code: 15,
        amphoe: 'โพธิ์ทอง',
        amphoe_code: 1504,
        zipcode: 14120,
    },
    {
        province: 'อ่างทอง',
        province_code: 15,
        amphoe: 'แสวงหา',
        amphoe_code: 1505,
        zipcode: 14150,
    },
    {
        province: 'อ่างทอง',
        province_code: 15,
        amphoe: 'วิเศษชัยชาญ',
        amphoe_code: 1506,
        zipcode: 14110,
    },
    {
        province: 'อ่างทอง',
        province_code: 15,
        amphoe: 'สามโก้',
        amphoe_code: 1507,
        zipcode: 14160,
    },
    {
        province: 'ลพบุรี',
        province_code: 16,
        amphoe: 'เมืองลพบุรี',
        amphoe_code: 1601,
        zipcode: 15000,
    },
    {
        province: 'ลพบุรี',
        province_code: 16,
        amphoe: 'พัฒนานิคม',
        amphoe_code: 1602,
        zipcode: 15140,
    },
    {
        province: 'ลพบุรี',
        province_code: 16,
        amphoe: 'โคกสำโรง',
        amphoe_code: 1603,
        zipcode: 15120,
    },
    {
        province: 'ลพบุรี',
        province_code: 16,
        amphoe: 'ชัยบาดาล',
        amphoe_code: 1604,
        zipcode: 15130,
    },
    {
        province: 'ลพบุรี',
        province_code: 16,
        amphoe: 'ท่าวุ้ง',
        amphoe_code: 1605,
        zipcode: 15150,
    },
    {
        province: 'ลพบุรี',
        province_code: 16,
        amphoe: 'บ้านหมี่',
        amphoe_code: 1606,
        zipcode: 15110,
    },
    {
        province: 'ลพบุรี',
        province_code: 16,
        amphoe: 'ท่าหลวง',
        amphoe_code: 1607,
        zipcode: 15230,
    },
    {
        province: 'ลพบุรี',
        province_code: 16,
        amphoe: 'สระโบสถ์',
        amphoe_code: 1608,
        zipcode: 15240,
    },
    {
        province: 'ลพบุรี',
        province_code: 16,
        amphoe: 'โคกเจริญ',
        amphoe_code: 1609,
        zipcode: 15250,
    },
    {
        province: 'ลพบุรี',
        province_code: 16,
        amphoe: 'ลำสนธิ',
        amphoe_code: 1610,
        zipcode: 15190,
    },
    {
        province: 'ลพบุรี',
        province_code: 16,
        amphoe: 'หนองม่วง',
        amphoe_code: 1611,
        zipcode: 15170,
    },
    {
        province: 'สิงห์บุรี',
        province_code: 17,
        amphoe: 'เมืองสิงห์บุรี',
        amphoe_code: 1701,
        zipcode: 16000,
    },
    {
        province: 'สิงห์บุรี',
        province_code: 17,
        amphoe: 'บางระจัน',
        amphoe_code: 1702,
        zipcode: 16130,
    },
    {
        province: 'สิงห์บุรี',
        province_code: 17,
        amphoe: 'ค่ายบางระจัน',
        amphoe_code: 1703,
        zipcode: 16150,
    },
    {
        province: 'สิงห์บุรี',
        province_code: 17,
        amphoe: 'พรหมบุรี',
        amphoe_code: 1704,
        zipcode: 16120,
    },
    {
        province: 'สิงห์บุรี',
        province_code: 17,
        amphoe: 'ท่าช้าง',
        amphoe_code: 1705,
        zipcode: 16140,
    },
    {
        province: 'สิงห์บุรี',
        province_code: 17,
        amphoe: 'อินทร์บุรี',
        amphoe_code: 1706,
        zipcode: 16110,
    },
    {
        province: 'ชัยนาท',
        province_code: 18,
        amphoe: 'เมืองชัยนาท',
        amphoe_code: 1801,
        zipcode: 17000,
    },
    {
        province: 'ชัยนาท',
        province_code: 18,
        amphoe: 'มโนรมย์',
        amphoe_code: 1802,
        zipcode: 17110,
    },
    {
        province: 'ชัยนาท',
        province_code: 18,
        amphoe: 'วัดสิงห์',
        amphoe_code: 1803,
        zipcode: 17120,
    },
    {
        province: 'ชัยนาท',
        province_code: 18,
        amphoe: 'สรรพยา',
        amphoe_code: 1804,
        zipcode: 17150,
    },
    {
        province: 'ชัยนาท',
        province_code: 18,
        amphoe: 'สรรคบุรี',
        amphoe_code: 1805,
        zipcode: 17140,
    },
    {
        province: 'ชัยนาท',
        province_code: 18,
        amphoe: 'หันคา',
        amphoe_code: 1806,
        zipcode: 17130,
    },
    {
        province: 'ชัยนาท',
        province_code: 18,
        amphoe: 'หนองมะโมง',
        amphoe_code: 1807,
        zipcode: 17120,
    },
    {
        province: 'ชัยนาท',
        province_code: 18,
        amphoe: 'เนินขาม',
        amphoe_code: 1808,
        zipcode: 17130,
    },
    {
        province: 'สระบุรี',
        province_code: 19,
        amphoe: 'เมืองสระบุรี',
        amphoe_code: 1901,
        zipcode: 18000,
    },
    {
        province: 'สระบุรี',
        province_code: 19,
        amphoe: 'แก่งคอย',
        amphoe_code: 1902,
        zipcode: 18110,
    },
    {
        province: 'สระบุรี',
        province_code: 19,
        amphoe: 'หนองแค',
        amphoe_code: 1903,
        zipcode: 18140,
    },
    {
        province: 'สระบุรี',
        province_code: 19,
        amphoe: 'วิหารแดง',
        amphoe_code: 1904,
        zipcode: 18150,
    },
    {
        province: 'สระบุรี',
        province_code: 19,
        amphoe: 'หนองแซง',
        amphoe_code: 1905,
        zipcode: 18170,
    },
    {
        province: 'สระบุรี',
        province_code: 19,
        amphoe: 'บ้านหมอ',
        amphoe_code: 1906,
        zipcode: 18130,
    },
    {
        province: 'สระบุรี',
        province_code: 19,
        amphoe: 'ดอนพุด',
        amphoe_code: 1907,
        zipcode: 18210,
    },
    {
        province: 'สระบุรี',
        province_code: 19,
        amphoe: 'หนองโดน',
        amphoe_code: 1908,
        zipcode: 18190,
    },
    {
        province: 'สระบุรี',
        province_code: 19,
        amphoe: 'พระพุทธบาท',
        amphoe_code: 1909,
        zipcode: 18120,
    },
    {
        province: 'สระบุรี',
        province_code: 19,
        amphoe: 'เสาไห้',
        amphoe_code: 1910,
        zipcode: 18160,
    },
    {
        province: 'สระบุรี',
        province_code: 19,
        amphoe: 'มวกเหล็ก',
        amphoe_code: 1911,
        zipcode: 18220,
    },
    {
        province: 'สระบุรี',
        province_code: 19,
        amphoe: 'วังม่วง',
        amphoe_code: 1912,
        zipcode: 18220,
    },
    {
        province: 'สระบุรี',
        province_code: 19,
        amphoe: 'เฉลิมพระเกียรติ',
        amphoe_code: 1913,
        zipcode: 18000,
    },
    {
        province: 'ชลบุรี',
        province_code: 20,
        amphoe: 'เมืองชลบุรี',
        amphoe_code: 2001,
        zipcode: 20000,
    },
    {
        province: 'ชลบุรี',
        province_code: 20,
        amphoe: 'บ้านบึง',
        amphoe_code: 2002,
        zipcode: 20220,
    },
    {
        province: 'ชลบุรี',
        province_code: 20,
        amphoe: 'หนองใหญ่',
        amphoe_code: 2003,
        zipcode: 20190,
    },
    {
        province: 'ชลบุรี',
        province_code: 20,
        amphoe: 'บางละมุง',
        amphoe_code: 2004,
        zipcode: 20150,
    },
    {
        province: 'ชลบุรี',
        province_code: 20,
        amphoe: 'พานทอง',
        amphoe_code: 2005,
        zipcode: 20160,
    },
    {
        province: 'ชลบุรี',
        province_code: 20,
        amphoe: 'พนัสนิคม',
        amphoe_code: 2006,
        zipcode: 20140,
    },
    {
        province: 'ชลบุรี',
        province_code: 20,
        amphoe: 'ศรีราชา',
        amphoe_code: 2007,
        zipcode: 20230,
    },
    {
        province: 'ชลบุรี',
        province_code: 20,
        amphoe: 'เกาะสีชัง',
        amphoe_code: 2008,
        zipcode: 20120,
    },
    {
        province: 'ชลบุรี',
        province_code: 20,
        amphoe: 'สัตหีบ',
        amphoe_code: 2009,
        zipcode: 20250,
    },
    {
        province: 'ชลบุรี',
        province_code: 20,
        amphoe: 'บ่อทอง',
        amphoe_code: 2010,
        zipcode: 20270,
    },
    {
        province: 'ชลบุรี',
        province_code: 20,
        amphoe: 'เกาะจันทร์',
        amphoe_code: 2011,
        zipcode: 20240,
    },
    {
        province: 'ระยอง',
        province_code: 21,
        amphoe: 'เมืองระยอง',
        amphoe_code: 2101,
        zipcode: 21100,
    },
    {
        province: 'ระยอง',
        province_code: 21,
        amphoe: 'บ้านฉาง',
        amphoe_code: 2102,
        zipcode: 21130,
    },
    {
        province: 'ระยอง',
        province_code: 21,
        amphoe: 'แกลง',
        amphoe_code: 2103,
        zipcode: 21110,
    },
    {
        province: 'ระยอง',
        province_code: 21,
        amphoe: 'วังจันทร์',
        amphoe_code: 2104,
        zipcode: 21210,
    },
    {
        province: 'ระยอง',
        province_code: 21,
        amphoe: 'บ้านค่าย',
        amphoe_code: 2105,
        zipcode: 21120,
    },
    {
        province: 'ระยอง',
        province_code: 21,
        amphoe: 'ปลวกแดง',
        amphoe_code: 2106,
        zipcode: 21140,
    },
    {
        province: 'ระยอง',
        province_code: 21,
        amphoe: 'เขาชะเมา',
        amphoe_code: 2107,
        zipcode: 21110,
    },
    {
        province: 'ระยอง',
        province_code: 21,
        amphoe: 'นิคมพัฒนา',
        amphoe_code: 2108,
        zipcode: 21180,
    },
    {
        province: 'จันทบุรี',
        province_code: 22,
        amphoe: 'เมืองจันทบุรี',
        amphoe_code: 2201,
        zipcode: 22000,
    },
    {
        province: 'จันทบุรี',
        province_code: 22,
        amphoe: 'ขลุง',
        amphoe_code: 2202,
        zipcode: 22110,
    },
    {
        province: 'จันทบุรี',
        province_code: 22,
        amphoe: 'ท่าใหม่',
        amphoe_code: 2203,
        zipcode: 22120,
    },
    {
        province: 'จันทบุรี',
        province_code: 22,
        amphoe: 'โป่งน้ำร้อน',
        amphoe_code: 2204,
        zipcode: 22140,
    },
    {
        province: 'จันทบุรี',
        province_code: 22,
        amphoe: 'มะขาม',
        amphoe_code: 2205,
        zipcode: 22150,
    },
    {
        province: 'จันทบุรี',
        province_code: 22,
        amphoe: 'แหลมสิงห์',
        amphoe_code: 2206,
        zipcode: 22190,
    },
    {
        province: 'จันทบุรี',
        province_code: 22,
        amphoe: 'สอยดาว',
        amphoe_code: 2207,
        zipcode: 22180,
    },
    {
        province: 'จันทบุรี',
        province_code: 22,
        amphoe: 'แก่งหางแมว',
        amphoe_code: 2208,
        zipcode: 22160,
    },
    {
        province: 'จันทบุรี',
        province_code: 22,
        amphoe: 'นายายอาม',
        amphoe_code: 2209,
        zipcode: 22170,
    },
    {
        province: 'จันทบุรี',
        province_code: 22,
        amphoe: 'เขาคิชฌกูฏ',
        amphoe_code: 2210,
        zipcode: 22210,
    },
    {
        province: 'ตราด',
        province_code: 23,
        amphoe: 'เมืองตราด',
        amphoe_code: 2301,
        zipcode: 23000,
    },
    {
        province: 'ตราด',
        province_code: 23,
        amphoe: 'คลองใหญ่',
        amphoe_code: 2302,
        zipcode: 23110,
    },
    {
        province: 'ตราด',
        province_code: 23,
        amphoe: 'เขาสมิง',
        amphoe_code: 2303,
        zipcode: 23130,
    },
    {
        province: 'ตราด',
        province_code: 23,
        amphoe: 'บ่อไร่',
        amphoe_code: 2304,
        zipcode: 23140,
    },
    {
        province: 'ตราด',
        province_code: 23,
        amphoe: 'แหลมงอบ',
        amphoe_code: 2305,
        zipcode: 23120,
    },
    {
        province: 'ตราด',
        province_code: 23,
        amphoe: 'เกาะกูด',
        amphoe_code: 2306,
        zipcode: 23000,
    },
    {
        province: 'ตราด',
        province_code: 23,
        amphoe: 'เกาะช้าง',
        amphoe_code: 2307,
        zipcode: 23170,
    },
    {
        province: 'ฉะเชิงเทรา',
        province_code: 24,
        amphoe: 'เมืองฉะเชิงเทรา',
        amphoe_code: 2401,
        zipcode: 24000,
    },
    {
        province: 'ฉะเชิงเทรา',
        province_code: 24,
        amphoe: 'บางคล้า',
        amphoe_code: 2402,
        zipcode: 24110,
    },
    {
        province: 'ฉะเชิงเทรา',
        province_code: 24,
        amphoe: 'บางน้ำเปรี้ยว',
        amphoe_code: 2403,
        zipcode: 24170,
    },
    {
        province: 'ฉะเชิงเทรา',
        province_code: 24,
        amphoe: 'บางปะกง',
        amphoe_code: 2404,
        zipcode: 24130,
    },
    {
        province: 'ฉะเชิงเทรา',
        province_code: 24,
        amphoe: 'บ้านโพธิ์',
        amphoe_code: 2405,
        zipcode: 24140,
    },
    {
        province: 'ฉะเชิงเทรา',
        province_code: 24,
        amphoe: 'พนมสารคาม',
        amphoe_code: 2406,
        zipcode: 24120,
    },
    {
        province: 'ฉะเชิงเทรา',
        province_code: 24,
        amphoe: 'ราชสาส์น',
        amphoe_code: 2407,
        zipcode: 24120,
    },
    {
        province: 'ฉะเชิงเทรา',
        province_code: 24,
        amphoe: 'สนามชัยเขต',
        amphoe_code: 2408,
        zipcode: 24160,
    },
    {
        province: 'ฉะเชิงเทรา',
        province_code: 24,
        amphoe: 'แปลงยาว',
        amphoe_code: 2409,
        zipcode: 24190,
    },
    {
        province: 'ฉะเชิงเทรา',
        province_code: 24,
        amphoe: 'ท่าตะเกียบ',
        amphoe_code: 2410,
        zipcode: 24160,
    },
    {
        province: 'ฉะเชิงเทรา',
        province_code: 24,
        amphoe: 'คลองเขื่อน',
        amphoe_code: 2411,
        zipcode: 24000,
    },
    {
        province: 'ปราจีนบุรี',
        province_code: 25,
        amphoe: 'เมืองปราจีนบุรี',
        amphoe_code: 2501,
        zipcode: 25000,
    },
    {
        province: 'ปราจีนบุรี',
        province_code: 25,
        amphoe: 'กบินทร์บุรี',
        amphoe_code: 2502,
        zipcode: 25110,
    },
    {
        province: 'ปราจีนบุรี',
        province_code: 25,
        amphoe: 'นาดี',
        amphoe_code: 2503,
        zipcode: 25220,
    },
    {
        province: 'ปราจีนบุรี',
        province_code: 25,
        amphoe: 'บ้านสร้าง',
        amphoe_code: 2506,
        zipcode: 25150,
    },
    {
        province: 'ปราจีนบุรี',
        province_code: 25,
        amphoe: 'ประจันตคาม',
        amphoe_code: 2507,
        zipcode: 25130,
    },
    {
        province: 'ปราจีนบุรี',
        province_code: 25,
        amphoe: 'ศรีมหาโพธิ',
        amphoe_code: 2508,
        zipcode: 25140,
    },
    {
        province: 'ปราจีนบุรี',
        province_code: 25,
        amphoe: 'ศรีมโหสถ',
        amphoe_code: 2509,
        zipcode: 25190,
    },
    {
        province: 'นครนายก',
        province_code: 26,
        amphoe: 'เมืองนครนายก',
        amphoe_code: 2601,
        zipcode: 26000,
    },
    {
        province: 'นครนายก',
        province_code: 26,
        amphoe: 'ปากพลี',
        amphoe_code: 2602,
        zipcode: 26130,
    },
    {
        province: 'นครนายก',
        province_code: 26,
        amphoe: 'บ้านนา',
        amphoe_code: 2603,
        zipcode: 26110,
    },
    {
        province: 'นครนายก',
        province_code: 26,
        amphoe: 'องครักษ์',
        amphoe_code: 2604,
        zipcode: 26120,
    },
    {
        province: 'สระแก้ว',
        province_code: 27,
        amphoe: 'เมืองสระแก้ว',
        amphoe_code: 2701,
        zipcode: 27000,
    },
    {
        province: 'สระแก้ว',
        province_code: 27,
        amphoe: 'คลองหาด',
        amphoe_code: 2702,
        zipcode: 27260,
    },
    {
        province: 'สระแก้ว',
        province_code: 27,
        amphoe: 'ตาพระยา',
        amphoe_code: 2703,
        zipcode: 27180,
    },
    {
        province: 'สระแก้ว',
        province_code: 27,
        amphoe: 'วังน้ำเย็น',
        amphoe_code: 2704,
        zipcode: 27210,
    },
    {
        province: 'สระแก้ว',
        province_code: 27,
        amphoe: 'วัฒนานคร',
        amphoe_code: 2705,
        zipcode: 27160,
    },
    {
        province: 'สระแก้ว',
        province_code: 27,
        amphoe: 'อรัญประเทศ',
        amphoe_code: 2706,
        zipcode: 27120,
    },
    {
        province: 'สระแก้ว',
        province_code: 27,
        amphoe: 'เขาฉกรรจ์',
        amphoe_code: 2707,
        zipcode: 27000,
    },
    {
        province: 'สระแก้ว',
        province_code: 27,
        amphoe: 'โคกสูง',
        amphoe_code: 2708,
        zipcode: 27180,
    },
    {
        province: 'สระแก้ว',
        province_code: 27,
        amphoe: 'วังสมบูรณ์',
        amphoe_code: 2709,
        zipcode: 27250,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'เมืองนครราชสีมา',
        amphoe_code: 3001,
        zipcode: 30310,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'ครบุรี',
        amphoe_code: 3002,
        zipcode: 30250,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'เสิงสาง',
        amphoe_code: 3003,
        zipcode: 30330,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'คง',
        amphoe_code: 3004,
        zipcode: 30260,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'บ้านเหลื่อม',
        amphoe_code: 3005,
        zipcode: 30350,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'จักราช',
        amphoe_code: 3006,
        zipcode: 30230,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'โชคชัย',
        amphoe_code: 3007,
        zipcode: 30190,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'ด่านขุนทด',
        amphoe_code: 3008,
        zipcode: 30210,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'โนนไทย',
        amphoe_code: 3009,
        zipcode: 30220,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'โนนสูง',
        amphoe_code: 3010,
        zipcode: 30160,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'ขามสะแกแสง',
        amphoe_code: 3011,
        zipcode: 30290,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'บัวใหญ่',
        amphoe_code: 3012,
        zipcode: 30120,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'ประทาย',
        amphoe_code: 3013,
        zipcode: 30180,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'ปักธงชัย',
        amphoe_code: 3014,
        zipcode: 30150,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'พิมาย',
        amphoe_code: 3015,
        zipcode: 30110,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'ห้วยแถลง',
        amphoe_code: 3016,
        zipcode: 30240,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'ชุมพวง',
        amphoe_code: 3017,
        zipcode: 30270,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'สูงเนิน',
        amphoe_code: 3018,
        zipcode: 30380,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'ขามทะเลสอ',
        amphoe_code: 3019,
        zipcode: 30280,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'สีคิ้ว',
        amphoe_code: 3020,
        zipcode: 30140,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'ปากช่อง',
        amphoe_code: 3021,
        zipcode: 30320,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'หนองบุญมาก',
        amphoe_code: 3022,
        zipcode: 30410,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'แก้งสนามนาง',
        amphoe_code: 3023,
        zipcode: 30440,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'โนนแดง',
        amphoe_code: 3024,
        zipcode: 30360,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'วังน้ำเขียว',
        amphoe_code: 3025,
        zipcode: 30150,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'เทพารักษ์',
        amphoe_code: 3026,
        zipcode: 30210,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'เมืองยาง',
        amphoe_code: 3027,
        zipcode: 30270,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'พระทองคำ',
        amphoe_code: 3028,
        zipcode: 30220,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'ลำทะเมนชัย',
        amphoe_code: 3029,
        zipcode: 30270,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'บัวลาย',
        amphoe_code: 3030,
        zipcode: 30120,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'สีดา',
        amphoe_code: 3031,
        zipcode: 30430,
    },
    {
        province: 'นครราชสีมา',
        province_code: 30,
        amphoe: 'เฉลิมพระเกียรติ',
        amphoe_code: 3032,
        zipcode: 30230,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'เมืองบุรีรัมย์',
        amphoe_code: 3101,
        zipcode: 31000,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'คูเมือง',
        amphoe_code: 3102,
        zipcode: 31190,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'กระสัง',
        amphoe_code: 3103,
        zipcode: 31160,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'นางรอง',
        amphoe_code: 3104,
        zipcode: 31110,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'หนองกี่',
        amphoe_code: 3105,
        zipcode: 31210,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'ละหานทราย',
        amphoe_code: 3106,
        zipcode: 31170,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'ประโคนชัย',
        amphoe_code: 3107,
        zipcode: 31140,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'บ้านกรวด',
        amphoe_code: 3108,
        zipcode: 31180,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'พุทไธสง',
        amphoe_code: 3109,
        zipcode: 31120,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'ลำปลายมาศ',
        amphoe_code: 3110,
        zipcode: 31130,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'สตึก',
        amphoe_code: 3111,
        zipcode: 31150,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'ปะคำ',
        amphoe_code: 3112,
        zipcode: 31220,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'นาโพธิ์',
        amphoe_code: 3113,
        zipcode: 31230,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'หนองหงส์',
        amphoe_code: 3114,
        zipcode: 31240,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'พลับพลาชัย',
        amphoe_code: 3115,
        zipcode: 31250,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'ห้วยราช',
        amphoe_code: 3116,
        zipcode: 31000,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'โนนสุวรรณ',
        amphoe_code: 3117,
        zipcode: 31110,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'ชำนิ',
        amphoe_code: 3118,
        zipcode: 31110,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'บ้านใหม่ไชยพจน์',
        amphoe_code: 3119,
        zipcode: 31120,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'โนนดินแดง',
        amphoe_code: 3120,
        zipcode: 31260,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'บ้านด่าน',
        amphoe_code: 3121,
        zipcode: 31000,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'แคนดง',
        amphoe_code: 3122,
        zipcode: 31150,
    },
    {
        province: 'บุรีรัมย์',
        province_code: 31,
        amphoe: 'เฉลิมพระเกียรติ',
        amphoe_code: 3123,
        zipcode: 31110,
    },
    {
        province: 'สุรินทร์',
        province_code: 32,
        amphoe: 'เมืองสุรินทร์',
        amphoe_code: 3201,
        zipcode: 32000,
    },
    {
        province: 'สุรินทร์',
        province_code: 32,
        amphoe: 'ชุมพลบุรี',
        amphoe_code: 3202,
        zipcode: 32190,
    },
    {
        province: 'สุรินทร์',
        province_code: 32,
        amphoe: 'ท่าตูม',
        amphoe_code: 3203,
        zipcode: 32120,
    },
    {
        province: 'สุรินทร์',
        province_code: 32,
        amphoe: 'จอมพระ',
        amphoe_code: 3204,
        zipcode: 32180,
    },
    {
        province: 'สุรินทร์',
        province_code: 32,
        amphoe: 'ปราสาท',
        amphoe_code: 3205,
        zipcode: 32140,
    },
    {
        province: 'สุรินทร์',
        province_code: 32,
        amphoe: 'กาบเชิง',
        amphoe_code: 3206,
        zipcode: 32210,
    },
    {
        province: 'สุรินทร์',
        province_code: 32,
        amphoe: 'รัตนบุรี',
        amphoe_code: 3207,
        zipcode: 32130,
    },
    {
        province: 'สุรินทร์',
        province_code: 32,
        amphoe: 'สนม',
        amphoe_code: 3208,
        zipcode: 32160,
    },
    {
        province: 'สุรินทร์',
        province_code: 32,
        amphoe: 'ศีขรภูมิ',
        amphoe_code: 3209,
        zipcode: 32110,
    },
    {
        province: 'สุรินทร์',
        province_code: 32,
        amphoe: 'สังขะ',
        amphoe_code: 3210,
        zipcode: 32150,
    },
    {
        province: 'สุรินทร์',
        province_code: 32,
        amphoe: 'ลำดวน',
        amphoe_code: 3211,
        zipcode: 32220,
    },
    {
        province: 'สุรินทร์',
        province_code: 32,
        amphoe: 'สำโรงทาบ',
        amphoe_code: 3212,
        zipcode: 32170,
    },
    {
        province: 'สุรินทร์',
        province_code: 32,
        amphoe: 'บัวเชด',
        amphoe_code: 3213,
        zipcode: 32230,
    },
    {
        province: 'สุรินทร์',
        province_code: 32,
        amphoe: 'พนมดงรัก',
        amphoe_code: 3214,
        zipcode: 32140,
    },
    {
        province: 'สุรินทร์',
        province_code: 32,
        amphoe: 'ศรีณรงค์',
        amphoe_code: 3215,
        zipcode: 32150,
    },
    {
        province: 'สุรินทร์',
        province_code: 32,
        amphoe: 'เขวาสินรินทร์',
        amphoe_code: 3216,
        zipcode: 32000,
    },
    {
        province: 'สุรินทร์',
        province_code: 32,
        amphoe: 'โนนนารายณ์',
        amphoe_code: 3217,
        zipcode: 32130,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'เมืองศรีสะเกษ',
        amphoe_code: 3301,
        zipcode: 33000,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'ยางชุมน้อย',
        amphoe_code: 3302,
        zipcode: 33190,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'กันทรารมย์',
        amphoe_code: 3303,
        zipcode: 33130,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'กันทรลักษ์',
        amphoe_code: 3304,
        zipcode: 33110,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'ขุขันธ์',
        amphoe_code: 3305,
        zipcode: 33140,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'ไพรบึง',
        amphoe_code: 3306,
        zipcode: 33180,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'ปรางค์กู่',
        amphoe_code: 3307,
        zipcode: 33170,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'ขุนหาญ',
        amphoe_code: 3308,
        zipcode: 33150,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'ราษีไศล',
        amphoe_code: 3309,
        zipcode: 33160,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'อุทุมพรพิสัย',
        amphoe_code: 3310,
        zipcode: 33120,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'บึงบูรพ์',
        amphoe_code: 3311,
        zipcode: 33220,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'ห้วยทับทัน',
        amphoe_code: 3312,
        zipcode: 33210,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'โนนคูณ',
        amphoe_code: 3313,
        zipcode: 33250,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'ศรีรัตนะ',
        amphoe_code: 3314,
        zipcode: 33240,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'น้ำเกลี้ยง',
        amphoe_code: 3315,
        zipcode: 33130,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'วังหิน',
        amphoe_code: 3316,
        zipcode: 33270,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'ภูสิงห์',
        amphoe_code: 3317,
        zipcode: 33140,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'เมืองจันทร์',
        amphoe_code: 3318,
        zipcode: 33120,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'เบญจลักษ์',
        amphoe_code: 3319,
        zipcode: 33110,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'พยุห์',
        amphoe_code: 3320,
        zipcode: 33230,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'โพธิ์ศรีสุวรรณ',
        amphoe_code: 3321,
        zipcode: 33120,
    },
    {
        province: 'ศรีสะเกษ',
        province_code: 33,
        amphoe: 'ศิลาลาด',
        amphoe_code: 3322,
        zipcode: 33160,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'เมืองอุบลราชธานี',
        amphoe_code: 3401,
        zipcode: 34000,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'ศรีเมืองใหม่',
        amphoe_code: 3402,
        zipcode: 34250,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'โขงเจียม',
        amphoe_code: 3403,
        zipcode: 34220,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'เขื่องใน',
        amphoe_code: 3404,
        zipcode: 34320,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'เขมราฐ',
        amphoe_code: 3405,
        zipcode: 34170,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'เดชอุดม',
        amphoe_code: 3407,
        zipcode: 34160,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'นาจะหลวย',
        amphoe_code: 3408,
        zipcode: 34280,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'น้ำยืน',
        amphoe_code: 3409,
        zipcode: 34260,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'บุณฑริก',
        amphoe_code: 3410,
        zipcode: 34230,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'ตระการพืชผล',
        amphoe_code: 3411,
        zipcode: 34130,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'กุดข้าวปุ้น',
        amphoe_code: 3412,
        zipcode: 34270,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'ม่วงสามสิบ',
        amphoe_code: 3414,
        zipcode: 34140,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'วารินชำราบ',
        amphoe_code: 3415,
        zipcode: 34190,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'พิบูลมังสาหาร',
        amphoe_code: 3419,
        zipcode: 34110,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'ตาลสุม',
        amphoe_code: 3420,
        zipcode: 34330,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'โพธิ์ไทร',
        amphoe_code: 3421,
        zipcode: 34340,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'สำโรง',
        amphoe_code: 3422,
        zipcode: 34360,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'ดอนมดแดง',
        amphoe_code: 3424,
        zipcode: 34000,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'สิรินธร',
        amphoe_code: 3425,
        zipcode: 34350,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'ทุ่งศรีอุดม',
        amphoe_code: 3426,
        zipcode: 34160,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'นาเยีย',
        amphoe_code: 3429,
        zipcode: 34160,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'นาตาล',
        amphoe_code: 3430,
        zipcode: 34170,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'เหล่าเสือโก้ก',
        amphoe_code: 3431,
        zipcode: 34000,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'สว่างวีระวงศ์',
        amphoe_code: 3432,
        zipcode: 34190,
    },
    {
        province: 'อุบลราชธานี',
        province_code: 34,
        amphoe: 'น้ำขุ่น',
        amphoe_code: 3433,
        zipcode: 34260,
    },
    {
        province: 'ยโสธร',
        province_code: 35,
        amphoe: 'เมืองยโสธร',
        amphoe_code: 3501,
        zipcode: 35000,
    },
    {
        province: 'ยโสธร',
        province_code: 35,
        amphoe: 'ทรายมูล',
        amphoe_code: 3502,
        zipcode: 35170,
    },
    {
        province: 'ยโสธร',
        province_code: 35,
        amphoe: 'กุดชุม',
        amphoe_code: 3503,
        zipcode: 35140,
    },
    {
        province: 'ยโสธร',
        province_code: 35,
        amphoe: 'คำเขื่อนแก้ว',
        amphoe_code: 3504,
        zipcode: 35110,
    },
    {
        province: 'ยโสธร',
        province_code: 35,
        amphoe: 'ป่าติ้ว',
        amphoe_code: 3505,
        zipcode: 35150,
    },
    {
        province: 'ยโสธร',
        province_code: 35,
        amphoe: 'มหาชนะชัย',
        amphoe_code: 3506,
        zipcode: 35130,
    },
    {
        province: 'ยโสธร',
        province_code: 35,
        amphoe: 'ค้อวัง',
        amphoe_code: 3507,
        zipcode: 35160,
    },
    {
        province: 'ยโสธร',
        province_code: 35,
        amphoe: 'เลิงนกทา',
        amphoe_code: 3508,
        zipcode: 35120,
    },
    {
        province: 'ยโสธร',
        province_code: 35,
        amphoe: 'ไทยเจริญ',
        amphoe_code: 3509,
        zipcode: 35120,
    },
    {
        province: 'ชัยภูมิ',
        province_code: 36,
        amphoe: 'เมืองชัยภูมิ',
        amphoe_code: 3601,
        zipcode: 36000,
    },
    {
        province: 'ชัยภูมิ',
        province_code: 36,
        amphoe: 'บ้านเขว้า',
        amphoe_code: 3602,
        zipcode: 36170,
    },
    {
        province: 'ชัยภูมิ',
        province_code: 36,
        amphoe: 'คอนสวรรค์',
        amphoe_code: 3603,
        zipcode: 36140,
    },
    {
        province: 'ชัยภูมิ',
        province_code: 36,
        amphoe: 'เกษตรสมบูรณ์',
        amphoe_code: 3604,
        zipcode: 36120,
    },
    {
        province: 'ชัยภูมิ',
        province_code: 36,
        amphoe: 'หนองบัวแดง',
        amphoe_code: 3605,
        zipcode: 36210,
    },
    {
        province: 'ชัยภูมิ',
        province_code: 36,
        amphoe: 'จัตุรัส',
        amphoe_code: 3606,
        zipcode: 36130,
    },
    {
        province: 'ชัยภูมิ',
        province_code: 36,
        amphoe: 'บำเหน็จณรงค์',
        amphoe_code: 3607,
        zipcode: 36160,
    },
    {
        province: 'ชัยภูมิ',
        province_code: 36,
        amphoe: 'หนองบัวระเหว',
        amphoe_code: 3608,
        zipcode: 36250,
    },
    {
        province: 'ชัยภูมิ',
        province_code: 36,
        amphoe: 'เทพสถิต',
        amphoe_code: 3609,
        zipcode: 36230,
    },
    {
        province: 'ชัยภูมิ',
        province_code: 36,
        amphoe: 'ภูเขียว',
        amphoe_code: 3610,
        zipcode: 36110,
    },
    {
        province: 'ชัยภูมิ',
        province_code: 36,
        amphoe: 'บ้านแท่น',
        amphoe_code: 3611,
        zipcode: 36190,
    },
    {
        province: 'ชัยภูมิ',
        province_code: 36,
        amphoe: 'แก้งคร้อ',
        amphoe_code: 3612,
        zipcode: 36150,
    },
    {
        province: 'ชัยภูมิ',
        province_code: 36,
        amphoe: 'คอนสาร',
        amphoe_code: 3613,
        zipcode: 36180,
    },
    {
        province: 'ชัยภูมิ',
        province_code: 36,
        amphoe: 'ภักดีชุมพล',
        amphoe_code: 3614,
        zipcode: 36260,
    },
    {
        province: 'ชัยภูมิ',
        province_code: 36,
        amphoe: 'เนินสง่า',
        amphoe_code: 3615,
        zipcode: 36130,
    },
    {
        province: 'ชัยภูมิ',
        province_code: 36,
        amphoe: 'ซับใหญ่',
        amphoe_code: 3616,
        zipcode: 36130,
    },
    {
        province: 'อำนาจเจริญ',
        province_code: 37,
        amphoe: 'เมืองอำนาจเจริญ',
        amphoe_code: 3701,
        zipcode: 37000,
    },
    {
        province: 'อำนาจเจริญ',
        province_code: 37,
        amphoe: 'ชานุมาน',
        amphoe_code: 3702,
        zipcode: 37210,
    },
    {
        province: 'อำนาจเจริญ',
        province_code: 37,
        amphoe: 'ปทุมราชวงศา',
        amphoe_code: 3703,
        zipcode: 37110,
    },
    {
        province: 'อำนาจเจริญ',
        province_code: 37,
        amphoe: 'พนา',
        amphoe_code: 3704,
        zipcode: 37180,
    },
    {
        province: 'อำนาจเจริญ',
        province_code: 37,
        amphoe: 'เสนางคนิคม',
        amphoe_code: 3705,
        zipcode: 37290,
    },
    {
        province: 'อำนาจเจริญ',
        province_code: 37,
        amphoe: 'หัวตะพาน',
        amphoe_code: 3706,
        zipcode: 37240,
    },
    {
        province: 'อำนาจเจริญ',
        province_code: 37,
        amphoe: 'ลืออำนาจ',
        amphoe_code: 3707,
        zipcode: 37120,
    },
    {
        province: 'หนองบัวลำภู',
        province_code: 39,
        amphoe: 'เมืองหนองบัวลำภู',
        amphoe_code: 3901,
        zipcode: 39000,
    },
    {
        province: 'หนองบัวลำภู',
        province_code: 39,
        amphoe: 'นากลาง',
        amphoe_code: 3902,
        zipcode: 39350,
    },
    {
        province: 'หนองบัวลำภู',
        province_code: 39,
        amphoe: 'โนนสัง',
        amphoe_code: 3903,
        zipcode: 39140,
    },
    {
        province: 'หนองบัวลำภู',
        province_code: 39,
        amphoe: 'ศรีบุญเรือง',
        amphoe_code: 3904,
        zipcode: 39180,
    },
    {
        province: 'หนองบัวลำภู',
        province_code: 39,
        amphoe: 'สุวรรณคูหา',
        amphoe_code: 3905,
        zipcode: 39270,
    },
    {
        province: 'หนองบัวลำภู',
        province_code: 39,
        amphoe: 'นาวัง',
        amphoe_code: 3906,
        zipcode: 39170,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'เมืองขอนแก่น',
        amphoe_code: 4001,
        zipcode: 40000,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'บ้านฝาง',
        amphoe_code: 4002,
        zipcode: 40270,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'พระยืน',
        amphoe_code: 4003,
        zipcode: 40320,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'หนองเรือ',
        amphoe_code: 4004,
        zipcode: 40210,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'ชุมแพ',
        amphoe_code: 4005,
        zipcode: 40130,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'สีชมพู',
        amphoe_code: 4006,
        zipcode: 40220,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'น้ำพอง',
        amphoe_code: 4007,
        zipcode: 40310,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'อุบลรัตน์',
        amphoe_code: 4008,
        zipcode: 40250,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'กระนวน',
        amphoe_code: 4009,
        zipcode: 40170,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'บ้านไผ่',
        amphoe_code: 4010,
        zipcode: 40110,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'เปือยน้อย',
        amphoe_code: 4011,
        zipcode: 40340,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'พล',
        amphoe_code: 4012,
        zipcode: 40120,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'แวงใหญ่',
        amphoe_code: 4013,
        zipcode: 40330,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'แวงน้อย',
        amphoe_code: 4014,
        zipcode: 40230,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'หนองสองห้อง',
        amphoe_code: 4015,
        zipcode: 40190,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'ภูเวียง',
        amphoe_code: 4016,
        zipcode: 40150,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'มัญจาคีรี',
        amphoe_code: 4017,
        zipcode: 40160,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'ชนบท',
        amphoe_code: 4018,
        zipcode: 40180,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'เขาสวนกวาง',
        amphoe_code: 4019,
        zipcode: 40280,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'ภูผาม่าน',
        amphoe_code: 4020,
        zipcode: 40350,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'ซำสูง',
        amphoe_code: 4021,
        zipcode: 40170,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'โคกโพธิ์ไชย',
        amphoe_code: 4022,
        zipcode: 40160,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'หนองนาคำ',
        amphoe_code: 4023,
        zipcode: 40150,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'บ้านแฮด',
        amphoe_code: 4024,
        zipcode: 40110,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'โนนศิลา',
        amphoe_code: 4025,
        zipcode: 40110,
    },
    {
        province: 'ขอนแก่น',
        province_code: 40,
        amphoe: 'เวียงเก่า',
        amphoe_code: 4029,
        zipcode: 40150,
    },
    {
        province: 'อุดรธานี',
        province_code: 41,
        amphoe: 'เมืองอุดรธานี',
        amphoe_code: 4101,
        zipcode: 41000,
    },
    {
        province: 'อุดรธานี',
        province_code: 41,
        amphoe: 'กุดจับ',
        amphoe_code: 4102,
        zipcode: 41250,
    },
    {
        province: 'อุดรธานี',
        province_code: 41,
        amphoe: 'หนองวัวซอ',
        amphoe_code: 4103,
        zipcode: 41220,
    },
    {
        province: 'อุดรธานี',
        province_code: 41,
        amphoe: 'กุมภวาปี',
        amphoe_code: 4104,
        zipcode: 41110,
    },
    {
        province: 'อุดรธานี',
        province_code: 41,
        amphoe: 'โนนสะอาด',
        amphoe_code: 4105,
        zipcode: 41240,
    },
    {
        province: 'อุดรธานี',
        province_code: 41,
        amphoe: 'หนองหาน',
        amphoe_code: 4106,
        zipcode: 41130,
    },
    {
        province: 'อุดรธานี',
        province_code: 41,
        amphoe: 'ทุ่งฝน',
        amphoe_code: 4107,
        zipcode: 41310,
    },
    {
        province: 'อุดรธานี',
        province_code: 41,
        amphoe: 'ไชยวาน',
        amphoe_code: 4108,
        zipcode: 41290,
    },
    {
        province: 'อุดรธานี',
        province_code: 41,
        amphoe: 'ศรีธาตุ',
        amphoe_code: 4109,
        zipcode: 41230,
    },
    {
        province: 'อุดรธานี',
        province_code: 41,
        amphoe: 'วังสามหมอ',
        amphoe_code: 4110,
        zipcode: 41280,
    },
    {
        province: 'อุดรธานี',
        province_code: 41,
        amphoe: 'บ้านดุง',
        amphoe_code: 4111,
        zipcode: 41190,
    },
    {
        province: 'อุดรธานี',
        province_code: 41,
        amphoe: 'บ้านผือ',
        amphoe_code: 4117,
        zipcode: 41160,
    },
    {
        province: 'อุดรธานี',
        province_code: 41,
        amphoe: 'น้ำโสม',
        amphoe_code: 4118,
        zipcode: 41210,
    },
    {
        province: 'อุดรธานี',
        province_code: 41,
        amphoe: 'เพ็ญ',
        amphoe_code: 4119,
        zipcode: 41150,
    },
    {
        province: 'อุดรธานี',
        province_code: 41,
        amphoe: 'สร้างคอม',
        amphoe_code: 4120,
        zipcode: 41260,
    },
    {
        province: 'อุดรธานี',
        province_code: 41,
        amphoe: 'หนองแสง',
        amphoe_code: 4121,
        zipcode: 41340,
    },
    {
        province: 'อุดรธานี',
        province_code: 41,
        amphoe: 'นายูง',
        amphoe_code: 4122,
        zipcode: 41380,
    },
    {
        province: 'อุดรธานี',
        province_code: 41,
        amphoe: 'พิบูลย์รักษ์',
        amphoe_code: 4123,
        zipcode: 41130,
    },
    {
        province: 'อุดรธานี',
        province_code: 41,
        amphoe: 'กู่แก้ว',
        amphoe_code: 4124,
        zipcode: 41130,
    },
    {
        province: 'อุดรธานี',
        province_code: 41,
        amphoe: 'ประจักษ์ศิลปาคม',
        amphoe_code: 4125,
        zipcode: 41110,
    },
    {
        province: 'เลย',
        province_code: 42,
        amphoe: 'เมืองเลย',
        amphoe_code: 4201,
        zipcode: 42000,
    },
    {
        province: 'เลย',
        province_code: 42,
        amphoe: 'นาด้วง',
        amphoe_code: 4202,
        zipcode: 42210,
    },
    {
        province: 'เลย',
        province_code: 42,
        amphoe: 'เชียงคาน',
        amphoe_code: 4203,
        zipcode: 42110,
    },
    {
        province: 'เลย',
        province_code: 42,
        amphoe: 'ปากชม',
        amphoe_code: 4204,
        zipcode: 42150,
    },
    {
        province: 'เลย',
        province_code: 42,
        amphoe: 'ด่านซ้าย',
        amphoe_code: 4205,
        zipcode: 42120,
    },
    {
        province: 'เลย',
        province_code: 42,
        amphoe: 'นาแห้ว',
        amphoe_code: 4206,
        zipcode: 42170,
    },
    {
        province: 'เลย',
        province_code: 42,
        amphoe: 'ภูเรือ',
        amphoe_code: 4207,
        zipcode: 42160,
    },
    {
        province: 'เลย',
        province_code: 42,
        amphoe: 'ท่าลี่',
        amphoe_code: 4208,
        zipcode: 42140,
    },
    {
        province: 'เลย',
        province_code: 42,
        amphoe: 'วังสะพุง',
        amphoe_code: 4209,
        zipcode: 42130,
    },
    {
        province: 'เลย',
        province_code: 42,
        amphoe: 'ภูกระดึง',
        amphoe_code: 4210,
        zipcode: 42180,
    },
    {
        province: 'เลย',
        province_code: 42,
        amphoe: 'ภูหลวง',
        amphoe_code: 4211,
        zipcode: 42230,
    },
    {
        province: 'เลย',
        province_code: 42,
        amphoe: 'ผาขาว',
        amphoe_code: 4212,
        zipcode: 42240,
    },
    {
        province: 'เลย',
        province_code: 42,
        amphoe: 'เอราวัณ',
        amphoe_code: 4213,
        zipcode: 42220,
    },
    {
        province: 'เลย',
        province_code: 42,
        amphoe: 'หนองหิน',
        amphoe_code: 4214,
        zipcode: 42190,
    },
    {
        province: 'หนองคาย',
        province_code: 43,
        amphoe: 'เมืองหนองคาย',
        amphoe_code: 4301,
        zipcode: 43000,
    },
    {
        province: 'หนองคาย',
        province_code: 43,
        amphoe: 'ท่าบ่อ',
        amphoe_code: 4302,
        zipcode: 43110,
    },
    {
        province: 'หนองคาย',
        province_code: 43,
        amphoe: 'โพนพิสัย',
        amphoe_code: 4305,
        zipcode: 43120,
    },
    {
        province: 'หนองคาย',
        province_code: 43,
        amphoe: 'ศรีเชียงใหม่',
        amphoe_code: 4307,
        zipcode: 43130,
    },
    {
        province: 'หนองคาย',
        province_code: 43,
        amphoe: 'สังคม',
        amphoe_code: 4308,
        zipcode: 43160,
    },
    {
        province: 'หนองคาย',
        province_code: 43,
        amphoe: 'สระใคร',
        amphoe_code: 4314,
        zipcode: 43100,
    },
    {
        province: 'หนองคาย',
        province_code: 43,
        amphoe: 'เฝ้าไร่',
        amphoe_code: 4315,
        zipcode: 43120,
    },
    {
        province: 'หนองคาย',
        province_code: 43,
        amphoe: 'รัตนวาปี',
        amphoe_code: 4316,
        zipcode: 43120,
    },
    {
        province: 'หนองคาย',
        province_code: 43,
        amphoe: 'โพธิ์ตาก',
        amphoe_code: 4317,
        zipcode: 43130,
    },
    {
        province: 'มหาสารคาม',
        province_code: 44,
        amphoe: 'เมืองมหาสารคาม',
        amphoe_code: 4401,
        zipcode: 44000,
    },
    {
        province: 'มหาสารคาม',
        province_code: 44,
        amphoe: 'แกดำ',
        amphoe_code: 4402,
        zipcode: 44190,
    },
    {
        province: 'มหาสารคาม',
        province_code: 44,
        amphoe: 'โกสุมพิสัย',
        amphoe_code: 4403,
        zipcode: 44140,
    },
    {
        province: 'มหาสารคาม',
        province_code: 44,
        amphoe: 'กันทรวิชัย',
        amphoe_code: 4404,
        zipcode: 44150,
    },
    {
        province: 'มหาสารคาม',
        province_code: 44,
        amphoe: 'เชียงยืน',
        amphoe_code: 4405,
        zipcode: 44160,
    },
    {
        province: 'มหาสารคาม',
        province_code: 44,
        amphoe: 'บรบือ',
        amphoe_code: 4406,
        zipcode: 44130,
    },
    {
        province: 'มหาสารคาม',
        province_code: 44,
        amphoe: 'นาเชือก',
        amphoe_code: 4407,
        zipcode: 44170,
    },
    {
        province: 'มหาสารคาม',
        province_code: 44,
        amphoe: 'พยัคฆภูมิพิสัย',
        amphoe_code: 4408,
        zipcode: 44110,
    },
    {
        province: 'มหาสารคาม',
        province_code: 44,
        amphoe: 'วาปีปทุม',
        amphoe_code: 4409,
        zipcode: 44120,
    },
    {
        province: 'มหาสารคาม',
        province_code: 44,
        amphoe: 'นาดูน',
        amphoe_code: 4410,
        zipcode: 44180,
    },
    {
        province: 'มหาสารคาม',
        province_code: 44,
        amphoe: 'ยางสีสุราช',
        amphoe_code: 4411,
        zipcode: 44210,
    },
    {
        province: 'มหาสารคาม',
        province_code: 44,
        amphoe: 'กุดรัง',
        amphoe_code: 4412,
        zipcode: 44130,
    },
    {
        province: 'มหาสารคาม',
        province_code: 44,
        amphoe: 'ชื่นชม',
        amphoe_code: 4413,
        zipcode: 44160,
    },
    {
        province: 'ร้อยเอ็ด',
        province_code: 45,
        amphoe: 'เมืองร้อยเอ็ด',
        amphoe_code: 4501,
        zipcode: 45000,
    },
    {
        province: 'ร้อยเอ็ด',
        province_code: 45,
        amphoe: 'เกษตรวิสัย',
        amphoe_code: 4502,
        zipcode: 45150,
    },
    {
        province: 'ร้อยเอ็ด',
        province_code: 45,
        amphoe: 'ปทุมรัตต์',
        amphoe_code: 4503,
        zipcode: 45190,
    },
    {
        province: 'ร้อยเอ็ด',
        province_code: 45,
        amphoe: 'ธวัชบุรี',
        amphoe_code: 4505,
        zipcode: 45170,
    },
    {
        province: 'ร้อยเอ็ด',
        province_code: 45,
        amphoe: 'พนมไพร',
        amphoe_code: 4506,
        zipcode: 45140,
    },
    {
        province: 'ร้อยเอ็ด',
        province_code: 45,
        amphoe: 'โพนทอง',
        amphoe_code: 4507,
        zipcode: 45110,
    },
    {
        province: 'ร้อยเอ็ด',
        province_code: 45,
        amphoe: 'โพธิ์ชัย',
        amphoe_code: 4508,
        zipcode: 45230,
    },
    {
        province: 'ร้อยเอ็ด',
        province_code: 45,
        amphoe: 'หนองพอก',
        amphoe_code: 4509,
        zipcode: 45210,
    },
    {
        province: 'ร้อยเอ็ด',
        province_code: 45,
        amphoe: 'เสลภูมิ',
        amphoe_code: 4510,
        zipcode: 45120,
    },
    {
        province: 'ร้อยเอ็ด',
        province_code: 45,
        amphoe: 'สุวรรณภูมิ',
        amphoe_code: 4511,
        zipcode: 45130,
    },
    {
        province: 'ร้อยเอ็ด',
        province_code: 45,
        amphoe: 'เมืองสรวง',
        amphoe_code: 4512,
        zipcode: 45220,
    },
    {
        province: 'ร้อยเอ็ด',
        province_code: 45,
        amphoe: 'โพนทราย',
        amphoe_code: 4513,
        zipcode: 45240,
    },
    {
        province: 'ร้อยเอ็ด',
        province_code: 45,
        amphoe: 'อาจสามารถ',
        amphoe_code: 4514,
        zipcode: 45160,
    },
    {
        province: 'ร้อยเอ็ด',
        province_code: 45,
        amphoe: 'เมยวดี',
        amphoe_code: 4515,
        zipcode: 45250,
    },
    {
        province: 'ร้อยเอ็ด',
        province_code: 45,
        amphoe: 'ศรีสมเด็จ',
        amphoe_code: 4516,
        zipcode: 45000,
    },
    {
        province: 'ร้อยเอ็ด',
        province_code: 45,
        amphoe: 'จังหาร',
        amphoe_code: 4517,
        zipcode: 45000,
    },
    {
        province: 'ร้อยเอ็ด',
        province_code: 45,
        amphoe: 'เชียงขวัญ',
        amphoe_code: 4518,
        zipcode: 45000,
    },
    {
        province: 'ร้อยเอ็ด',
        province_code: 45,
        amphoe: 'หนองฮี',
        amphoe_code: 4519,
        zipcode: 45140,
    },
    {
        province: 'ร้อยเอ็ด',
        province_code: 45,
        amphoe: 'ทุ่งเขาหลวง',
        amphoe_code: 4520,
        zipcode: 45170,
    },
    {
        province: 'กาฬสินธุ์',
        province_code: 46,
        amphoe: 'เมืองกาฬสินธุ์',
        amphoe_code: 4601,
        zipcode: 46000,
    },
    {
        province: 'กาฬสินธุ์',
        province_code: 46,
        amphoe: 'นามน',
        amphoe_code: 4602,
        zipcode: 46230,
    },
    {
        province: 'กาฬสินธุ์',
        province_code: 46,
        amphoe: 'กมลาไสย',
        amphoe_code: 4603,
        zipcode: 46130,
    },
    {
        province: 'กาฬสินธุ์',
        province_code: 46,
        amphoe: 'ร่องคำ',
        amphoe_code: 4604,
        zipcode: 46210,
    },
    {
        province: 'กาฬสินธุ์',
        province_code: 46,
        amphoe: 'กุฉินารายณ์',
        amphoe_code: 4605,
        zipcode: 46110,
    },
    {
        province: 'กาฬสินธุ์',
        province_code: 46,
        amphoe: 'เขาวง',
        amphoe_code: 4606,
        zipcode: 46160,
    },
    {
        province: 'กาฬสินธุ์',
        province_code: 46,
        amphoe: 'ยางตลาด',
        amphoe_code: 4607,
        zipcode: 46120,
    },
    {
        province: 'กาฬสินธุ์',
        province_code: 46,
        amphoe: 'ห้วยเม็ก',
        amphoe_code: 4608,
        zipcode: 46170,
    },
    {
        province: 'กาฬสินธุ์',
        province_code: 46,
        amphoe: 'สหัสขันธ์',
        amphoe_code: 4609,
        zipcode: 46140,
    },
    {
        province: 'กาฬสินธุ์',
        province_code: 46,
        amphoe: 'คำม่วง',
        amphoe_code: 4610,
        zipcode: 46180,
    },
    {
        province: 'กาฬสินธุ์',
        province_code: 46,
        amphoe: 'ท่าคันโท',
        amphoe_code: 4611,
        zipcode: 46190,
    },
    {
        province: 'กาฬสินธุ์',
        province_code: 46,
        amphoe: 'หนองกุงศรี',
        amphoe_code: 4612,
        zipcode: 46220,
    },
    {
        province: 'กาฬสินธุ์',
        province_code: 46,
        amphoe: 'สมเด็จ',
        amphoe_code: 4613,
        zipcode: 46150,
    },
    {
        province: 'กาฬสินธุ์',
        province_code: 46,
        amphoe: 'ห้วยผึ้ง',
        amphoe_code: 4614,
        zipcode: 46240,
    },
    {
        province: 'กาฬสินธุ์',
        province_code: 46,
        amphoe: 'สามชัย',
        amphoe_code: 4615,
        zipcode: 46180,
    },
    {
        province: 'กาฬสินธุ์',
        province_code: 46,
        amphoe: 'นาคู',
        amphoe_code: 4616,
        zipcode: 46160,
    },
    {
        province: 'กาฬสินธุ์',
        province_code: 46,
        amphoe: 'ดอนจาน',
        amphoe_code: 4617,
        zipcode: 46000,
    },
    {
        province: 'กาฬสินธุ์',
        province_code: 46,
        amphoe: 'ฆ้องชัย',
        amphoe_code: 4618,
        zipcode: 46130,
    },
    {
        province: 'สกลนคร',
        province_code: 47,
        amphoe: 'เมืองสกลนคร',
        amphoe_code: 4701,
        zipcode: 47220,
    },
    {
        province: 'สกลนคร',
        province_code: 47,
        amphoe: 'กุสุมาลย์',
        amphoe_code: 4702,
        zipcode: 47210,
    },
    {
        province: 'สกลนคร',
        province_code: 47,
        amphoe: 'กุดบาก',
        amphoe_code: 4703,
        zipcode: 47180,
    },
    {
        province: 'สกลนคร',
        province_code: 47,
        amphoe: 'พรรณานิคม',
        amphoe_code: 4704,
        zipcode: 47130,
    },
    {
        province: 'สกลนคร',
        province_code: 47,
        amphoe: 'พังโคน',
        amphoe_code: 4705,
        zipcode: 47160,
    },
    {
        province: 'สกลนคร',
        province_code: 47,
        amphoe: 'วาริชภูมิ',
        amphoe_code: 4706,
        zipcode: 47150,
    },
    {
        province: 'สกลนคร',
        province_code: 47,
        amphoe: 'นิคมน้ำอูน',
        amphoe_code: 4707,
        zipcode: 47270,
    },
    {
        province: 'สกลนคร',
        province_code: 47,
        amphoe: 'วานรนิวาส',
        amphoe_code: 4708,
        zipcode: 47120,
    },
    {
        province: 'สกลนคร',
        province_code: 47,
        amphoe: 'คำตากล้า',
        amphoe_code: 4709,
        zipcode: 47250,
    },
    {
        province: 'สกลนคร',
        province_code: 47,
        amphoe: 'บ้านม่วง',
        amphoe_code: 4710,
        zipcode: 47140,
    },
    {
        province: 'สกลนคร',
        province_code: 47,
        amphoe: 'อากาศอำนวย',
        amphoe_code: 4711,
        zipcode: 47170,
    },
    {
        province: 'สกลนคร',
        province_code: 47,
        amphoe: 'สว่างแดนดิน',
        amphoe_code: 4712,
        zipcode: 47110,
    },
    {
        province: 'สกลนคร',
        province_code: 47,
        amphoe: 'ส่องดาว',
        amphoe_code: 4713,
        zipcode: 47190,
    },
    {
        province: 'สกลนคร',
        province_code: 47,
        amphoe: 'เต่างอย',
        amphoe_code: 4714,
        zipcode: 47260,
    },
    {
        province: 'สกลนคร',
        province_code: 47,
        amphoe: 'โคกศรีสุพรรณ',
        amphoe_code: 4715,
        zipcode: 47280,
    },
    {
        province: 'สกลนคร',
        province_code: 47,
        amphoe: 'เจริญศิลป์',
        amphoe_code: 4716,
        zipcode: 47290,
    },
    {
        province: 'สกลนคร',
        province_code: 47,
        amphoe: 'โพนนาแก้ว',
        amphoe_code: 4717,
        zipcode: 47230,
    },
    {
        province: 'สกลนคร',
        province_code: 47,
        amphoe: 'ภูพาน',
        amphoe_code: 4718,
        zipcode: 47180,
    },
    {
        province: 'นครพนม',
        province_code: 48,
        amphoe: 'เมืองนครพนม',
        amphoe_code: 4801,
        zipcode: 48000,
    },
    {
        province: 'นครพนม',
        province_code: 48,
        amphoe: 'ปลาปาก',
        amphoe_code: 4802,
        zipcode: 48160,
    },
    {
        province: 'นครพนม',
        province_code: 48,
        amphoe: 'ท่าอุเทน',
        amphoe_code: 4803,
        zipcode: 48120,
    },
    {
        province: 'นครพนม',
        province_code: 48,
        amphoe: 'บ้านแพง',
        amphoe_code: 4804,
        zipcode: 48140,
    },
    {
        province: 'นครพนม',
        province_code: 48,
        amphoe: 'ธาตุพนม',
        amphoe_code: 4805,
        zipcode: 48110,
    },
    {
        province: 'นครพนม',
        province_code: 48,
        amphoe: 'เรณูนคร',
        amphoe_code: 4806,
        zipcode: 48170,
    },
    {
        province: 'นครพนม',
        province_code: 48,
        amphoe: 'นาแก',
        amphoe_code: 4807,
        zipcode: 48130,
    },
    {
        province: 'นครพนม',
        province_code: 48,
        amphoe: 'ศรีสงคราม',
        amphoe_code: 4808,
        zipcode: 48150,
    },
    {
        province: 'นครพนม',
        province_code: 48,
        amphoe: 'นาหว้า',
        amphoe_code: 4809,
        zipcode: 48180,
    },
    {
        province: 'นครพนม',
        province_code: 48,
        amphoe: 'โพนสวรรค์',
        amphoe_code: 4810,
        zipcode: 48190,
    },
    {
        province: 'นครพนม',
        province_code: 48,
        amphoe: 'นาทม',
        amphoe_code: 4811,
        zipcode: 48140,
    },
    {
        province: 'นครพนม',
        province_code: 48,
        amphoe: 'วังยาง',
        amphoe_code: 4812,
        zipcode: 48130,
    },
    {
        province: 'มุกดาหาร',
        province_code: 49,
        amphoe: 'เมืองมุกดาหาร',
        amphoe_code: 4901,
        zipcode: 49000,
    },
    {
        province: 'มุกดาหาร',
        province_code: 49,
        amphoe: 'นิคมคำสร้อย',
        amphoe_code: 4902,
        zipcode: 49130,
    },
    {
        province: 'มุกดาหาร',
        province_code: 49,
        amphoe: 'ดอนตาล',
        amphoe_code: 4903,
        zipcode: 49120,
    },
    {
        province: 'มุกดาหาร',
        province_code: 49,
        amphoe: 'ดงหลวง',
        amphoe_code: 4904,
        zipcode: 49140,
    },
    {
        province: 'มุกดาหาร',
        province_code: 49,
        amphoe: 'คำชะอี',
        amphoe_code: 4905,
        zipcode: 49110,
    },
    {
        province: 'มุกดาหาร',
        province_code: 49,
        amphoe: 'หว้านใหญ่',
        amphoe_code: 4906,
        zipcode: 49150,
    },
    {
        province: 'มุกดาหาร',
        province_code: 49,
        amphoe: 'หนองสูง',
        amphoe_code: 4907,
        zipcode: 49160,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'เมืองเชียงใหม่',
        amphoe_code: 5001,
        zipcode: 50100,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'จอมทอง',
        amphoe_code: 5002,
        zipcode: 50160,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'แม่แจ่ม',
        amphoe_code: 5003,
        zipcode: 50270,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'เชียงดาว',
        amphoe_code: 5004,
        zipcode: 50170,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'ดอยสะเก็ด',
        amphoe_code: 5005,
        zipcode: 50220,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'แม่แตง',
        amphoe_code: 5006,
        zipcode: 50150,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'แม่ริม',
        amphoe_code: 5007,
        zipcode: 50180,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'สะเมิง',
        amphoe_code: 5008,
        zipcode: 50250,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'ฝาง',
        amphoe_code: 5009,
        zipcode: 50110,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'แม่อาย',
        amphoe_code: 5010,
        zipcode: 50280,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'พร้าว',
        amphoe_code: 5011,
        zipcode: 50190,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'สันป่าตอง',
        amphoe_code: 5012,
        zipcode: 50120,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'สันกำแพง',
        amphoe_code: 5013,
        zipcode: 50130,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'สันทราย',
        amphoe_code: 5014,
        zipcode: 50210,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'หางดง',
        amphoe_code: 5015,
        zipcode: 50230,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'ฮอด',
        amphoe_code: 5016,
        zipcode: 50240,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'ดอยเต่า',
        amphoe_code: 5017,
        zipcode: 50260,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'อมก๋อย',
        amphoe_code: 5018,
        zipcode: 50310,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'สารภี',
        amphoe_code: 5019,
        zipcode: 50140,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'เวียงแหง',
        amphoe_code: 5020,
        zipcode: 50350,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'ไชยปราการ',
        amphoe_code: 5021,
        zipcode: 50320,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'แม่วาง',
        amphoe_code: 5022,
        zipcode: 50360,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'แม่ออน',
        amphoe_code: 5023,
        zipcode: 50130,
    },
    {
        province: 'เชียงใหม่',
        province_code: 50,
        amphoe: 'ดอยหล่อ',
        amphoe_code: 5024,
        zipcode: 50160,
    },
    {
        province: 'ลำพูน',
        province_code: 51,
        amphoe: 'เมืองลำพูน',
        amphoe_code: 5101,
        zipcode: 51000,
    },
    {
        province: 'ลำพูน',
        province_code: 51,
        amphoe: 'แม่ทา',
        amphoe_code: 5102,
        zipcode: 51170,
    },
    {
        province: 'ลำพูน',
        province_code: 51,
        amphoe: 'บ้านโฮ่ง',
        amphoe_code: 5103,
        zipcode: 51130,
    },
    {
        province: 'ลำพูน',
        province_code: 51,
        amphoe: 'ลี้',
        amphoe_code: 5104,
        zipcode: 51110,
    },
    {
        province: 'ลำพูน',
        province_code: 51,
        amphoe: 'ทุ่งหัวช้าง',
        amphoe_code: 5105,
        zipcode: 51160,
    },
    {
        province: 'ลำพูน',
        province_code: 51,
        amphoe: 'ป่าซาง',
        amphoe_code: 5106,
        zipcode: 51120,
    },
    {
        province: 'ลำพูน',
        province_code: 51,
        amphoe: 'บ้านธิ',
        amphoe_code: 5107,
        zipcode: 51180,
    },
    {
        province: 'ลำพูน',
        province_code: 51,
        amphoe: 'เวียงหนองล่อง',
        amphoe_code: 5108,
        zipcode: 51120,
    },
    {
        province: 'ลำปาง',
        province_code: 52,
        amphoe: 'เมืองลำปาง',
        amphoe_code: 5201,
        zipcode: 52000,
    },
    {
        province: 'ลำปาง',
        province_code: 52,
        amphoe: 'แม่เมาะ',
        amphoe_code: 5202,
        zipcode: 52220,
    },
    {
        province: 'ลำปาง',
        province_code: 52,
        amphoe: 'เกาะคา',
        amphoe_code: 5203,
        zipcode: 52130,
    },
    {
        province: 'ลำปาง',
        province_code: 52,
        amphoe: 'เสริมงาม',
        amphoe_code: 5204,
        zipcode: 52210,
    },
    {
        province: 'ลำปาง',
        province_code: 52,
        amphoe: 'งาว',
        amphoe_code: 5205,
        zipcode: 52110,
    },
    {
        province: 'ลำปาง',
        province_code: 52,
        amphoe: 'แจ้ห่ม',
        amphoe_code: 5206,
        zipcode: 52120,
    },
    {
        province: 'ลำปาง',
        province_code: 52,
        amphoe: 'วังเหนือ',
        amphoe_code: 5207,
        zipcode: 52140,
    },
    {
        province: 'ลำปาง',
        province_code: 52,
        amphoe: 'เถิน',
        amphoe_code: 5208,
        zipcode: 52160,
    },
    {
        province: 'ลำปาง',
        province_code: 52,
        amphoe: 'แม่พริก',
        amphoe_code: 5209,
        zipcode: 52180,
    },
    {
        province: 'ลำปาง',
        province_code: 52,
        amphoe: 'แม่ทะ',
        amphoe_code: 5210,
        zipcode: 52150,
    },
    {
        province: 'ลำปาง',
        province_code: 52,
        amphoe: 'สบปราบ',
        amphoe_code: 5211,
        zipcode: 52170,
    },
    {
        province: 'ลำปาง',
        province_code: 52,
        amphoe: 'ห้างฉัตร',
        amphoe_code: 5212,
        zipcode: 52190,
    },
    {
        province: 'ลำปาง',
        province_code: 52,
        amphoe: 'เมืองปาน',
        amphoe_code: 5213,
        zipcode: 52240,
    },
    {
        province: 'อุตรดิตถ์',
        province_code: 53,
        amphoe: 'เมืองอุตรดิตถ์',
        amphoe_code: 5301,
        zipcode: 53000,
    },
    {
        province: 'อุตรดิตถ์',
        province_code: 53,
        amphoe: 'ตรอน',
        amphoe_code: 5302,
        zipcode: 53140,
    },
    {
        province: 'อุตรดิตถ์',
        province_code: 53,
        amphoe: 'ท่าปลา',
        amphoe_code: 5303,
        zipcode: 53150,
    },
    {
        province: 'อุตรดิตถ์',
        province_code: 53,
        amphoe: 'น้ำปาด',
        amphoe_code: 5304,
        zipcode: 53110,
    },
    {
        province: 'อุตรดิตถ์',
        province_code: 53,
        amphoe: 'ฟากท่า',
        amphoe_code: 5305,
        zipcode: 53160,
    },
    {
        province: 'อุตรดิตถ์',
        province_code: 53,
        amphoe: 'บ้านโคก',
        amphoe_code: 5306,
        zipcode: 53180,
    },
    {
        province: 'อุตรดิตถ์',
        province_code: 53,
        amphoe: 'พิชัย',
        amphoe_code: 5307,
        zipcode: 53120,
    },
    {
        province: 'อุตรดิตถ์',
        province_code: 53,
        amphoe: 'ลับแล',
        amphoe_code: 5308,
        zipcode: 53130,
    },
    {
        province: 'อุตรดิตถ์',
        province_code: 53,
        amphoe: 'ทองแสนขัน',
        amphoe_code: 5309,
        zipcode: 53230,
    },
    {
        province: 'แพร่',
        province_code: 54,
        amphoe: 'เมืองแพร่',
        amphoe_code: 5401,
        zipcode: 54000,
    },
    {
        province: 'แพร่',
        province_code: 54,
        amphoe: 'ร้องกวาง',
        amphoe_code: 5402,
        zipcode: 54140,
    },
    {
        province: 'แพร่',
        province_code: 54,
        amphoe: 'ลอง',
        amphoe_code: 5403,
        zipcode: 54150,
    },
    {
        province: 'แพร่',
        province_code: 54,
        amphoe: 'สูงเม่น',
        amphoe_code: 5404,
        zipcode: 54130,
    },
    {
        province: 'แพร่',
        province_code: 54,
        amphoe: 'เด่นชัย',
        amphoe_code: 5405,
        zipcode: 54110,
    },
    {
        province: 'แพร่',
        province_code: 54,
        amphoe: 'สอง',
        amphoe_code: 5406,
        zipcode: 54120,
    },
    {
        province: 'แพร่',
        province_code: 54,
        amphoe: 'วังชิ้น',
        amphoe_code: 5407,
        zipcode: 54160,
    },
    {
        province: 'แพร่',
        province_code: 54,
        amphoe: 'หนองม่วงไข่',
        amphoe_code: 5408,
        zipcode: 54170,
    },
    {
        province: 'น่าน',
        province_code: 55,
        amphoe: 'เมืองน่าน',
        amphoe_code: 5501,
        zipcode: 55000,
    },
    {
        province: 'น่าน',
        province_code: 55,
        amphoe: 'แม่จริม',
        amphoe_code: 5502,
        zipcode: 55170,
    },
    {
        province: 'น่าน',
        province_code: 55,
        amphoe: 'บ้านหลวง',
        amphoe_code: 5503,
        zipcode: 55190,
    },
    {
        province: 'น่าน',
        province_code: 55,
        amphoe: 'นาน้อย',
        amphoe_code: 5504,
        zipcode: 55150,
    },
    {
        province: 'น่าน',
        province_code: 55,
        amphoe: 'ปัว',
        amphoe_code: 5505,
        zipcode: 55120,
    },
    {
        province: 'น่าน',
        province_code: 55,
        amphoe: 'ท่าวังผา',
        amphoe_code: 5506,
        zipcode: 55140,
    },
    {
        province: 'น่าน',
        province_code: 55,
        amphoe: 'เวียงสา',
        amphoe_code: 5507,
        zipcode: 55110,
    },
    {
        province: 'น่าน',
        province_code: 55,
        amphoe: 'ทุ่งช้าง',
        amphoe_code: 5508,
        zipcode: 55130,
    },
    {
        province: 'น่าน',
        province_code: 55,
        amphoe: 'เชียงกลาง',
        amphoe_code: 5509,
        zipcode: 55160,
    },
    {
        province: 'น่าน',
        province_code: 55,
        amphoe: 'นาหมื่น',
        amphoe_code: 5510,
        zipcode: 55180,
    },
    {
        province: 'น่าน',
        province_code: 55,
        amphoe: 'สันติสุข',
        amphoe_code: 5511,
        zipcode: 55210,
    },
    {
        province: 'น่าน',
        province_code: 55,
        amphoe: 'บ่อเกลือ',
        amphoe_code: 5512,
        zipcode: 55220,
    },
    {
        province: 'น่าน',
        province_code: 55,
        amphoe: 'สองแคว',
        amphoe_code: 5513,
        zipcode: 55160,
    },
    {
        province: 'น่าน',
        province_code: 55,
        amphoe: 'ภูเพียง',
        amphoe_code: 5514,
        zipcode: 55000,
    },
    {
        province: 'น่าน',
        province_code: 55,
        amphoe: 'เฉลิมพระเกียรติ',
        amphoe_code: 5515,
        zipcode: 55130,
    },
    {
        province: 'พะเยา',
        province_code: 56,
        amphoe: 'เมืองพะเยา',
        amphoe_code: 5601,
        zipcode: 56000,
    },
    {
        province: 'พะเยา',
        province_code: 56,
        amphoe: 'จุน',
        amphoe_code: 5602,
        zipcode: 56150,
    },
    {
        province: 'พะเยา',
        province_code: 56,
        amphoe: 'เชียงคำ',
        amphoe_code: 5603,
        zipcode: 56110,
    },
    {
        province: 'พะเยา',
        province_code: 56,
        amphoe: 'เชียงม่วน',
        amphoe_code: 5604,
        zipcode: 56160,
    },
    {
        province: 'พะเยา',
        province_code: 56,
        amphoe: 'ดอกคำใต้',
        amphoe_code: 5605,
        zipcode: 56120,
    },
    {
        province: 'พะเยา',
        province_code: 56,
        amphoe: 'ปง',
        amphoe_code: 5606,
        zipcode: 56140,
    },
    {
        province: 'พะเยา',
        province_code: 56,
        amphoe: 'แม่ใจ',
        amphoe_code: 5607,
        zipcode: 56130,
    },
    {
        province: 'พะเยา',
        province_code: 56,
        amphoe: 'ภูซาง',
        amphoe_code: 5608,
        zipcode: 56110,
    },
    {
        province: 'พะเยา',
        province_code: 56,
        amphoe: 'ภูกามยาว',
        amphoe_code: 5609,
        zipcode: 56000,
    },
    {
        province: 'เชียงราย',
        province_code: 57,
        amphoe: 'เมืองเชียงราย',
        amphoe_code: 5701,
        zipcode: 57000,
    },
    {
        province: 'เชียงราย',
        province_code: 57,
        amphoe: 'เวียงชัย',
        amphoe_code: 5702,
        zipcode: 57210,
    },
    {
        province: 'เชียงราย',
        province_code: 57,
        amphoe: 'เชียงของ',
        amphoe_code: 5703,
        zipcode: 57140,
    },
    {
        province: 'เชียงราย',
        province_code: 57,
        amphoe: 'เทิง',
        amphoe_code: 5704,
        zipcode: 57160,
    },
    {
        province: 'เชียงราย',
        province_code: 57,
        amphoe: 'พาน',
        amphoe_code: 5705,
        zipcode: 57120,
    },
    {
        province: 'เชียงราย',
        province_code: 57,
        amphoe: 'ป่าแดด',
        amphoe_code: 5706,
        zipcode: 57190,
    },
    {
        province: 'เชียงราย',
        province_code: 57,
        amphoe: 'แม่จัน',
        amphoe_code: 5707,
        zipcode: 57110,
    },
    {
        province: 'เชียงราย',
        province_code: 57,
        amphoe: 'เชียงแสน',
        amphoe_code: 5708,
        zipcode: 57150,
    },
    {
        province: 'เชียงราย',
        province_code: 57,
        amphoe: 'แม่สาย',
        amphoe_code: 5709,
        zipcode: 57220,
    },
    {
        province: 'เชียงราย',
        province_code: 57,
        amphoe: 'แม่สรวย',
        amphoe_code: 5710,
        zipcode: 57180,
    },
    {
        province: 'เชียงราย',
        province_code: 57,
        amphoe: 'เวียงป่าเป้า',
        amphoe_code: 5711,
        zipcode: 57170,
    },
    {
        province: 'เชียงราย',
        province_code: 57,
        amphoe: 'พญาเม็งราย',
        amphoe_code: 5712,
        zipcode: 57290,
    },
    {
        province: 'เชียงราย',
        province_code: 57,
        amphoe: 'เวียงแก่น',
        amphoe_code: 5713,
        zipcode: 57310,
    },
    {
        province: 'เชียงราย',
        province_code: 57,
        amphoe: 'ขุนตาล',
        amphoe_code: 5714,
        zipcode: 57340,
    },
    {
        province: 'เชียงราย',
        province_code: 57,
        amphoe: 'แม่ฟ้าหลวง',
        amphoe_code: 5715,
        zipcode: 57240,
    },
    {
        province: 'เชียงราย',
        province_code: 57,
        amphoe: 'แม่ลาว',
        amphoe_code: 5716,
        zipcode: 57250,
    },
    {
        province: 'เชียงราย',
        province_code: 57,
        amphoe: 'เวียงเชียงรุ้ง',
        amphoe_code: 5717,
        zipcode: 57210,
    },
    {
        province: 'เชียงราย',
        province_code: 57,
        amphoe: 'ดอยหลวง',
        amphoe_code: 5718,
        zipcode: 57110,
    },
    {
        province: 'แม่ฮ่องสอน',
        province_code: 58,
        amphoe: 'เมืองแม่ฮ่องสอน',
        amphoe_code: 5801,
        zipcode: 58000,
    },
    {
        province: 'แม่ฮ่องสอน',
        province_code: 58,
        amphoe: 'ขุนยวม',
        amphoe_code: 5802,
        zipcode: 58140,
    },
    {
        province: 'แม่ฮ่องสอน',
        province_code: 58,
        amphoe: 'ปาย',
        amphoe_code: 5803,
        zipcode: 58130,
    },
    {
        province: 'แม่ฮ่องสอน',
        province_code: 58,
        amphoe: 'แม่สะเรียง',
        amphoe_code: 5804,
        zipcode: 58110,
    },
    {
        province: 'แม่ฮ่องสอน',
        province_code: 58,
        amphoe: 'แม่ลาน้อย',
        amphoe_code: 5805,
        zipcode: 58120,
    },
    {
        province: 'แม่ฮ่องสอน',
        province_code: 58,
        amphoe: 'สบเมย',
        amphoe_code: 5806,
        zipcode: 58110,
    },
    {
        province: 'แม่ฮ่องสอน',
        province_code: 58,
        amphoe: 'ปางมะผ้า',
        amphoe_code: 5807,
        zipcode: 58150,
    },
    {
        province: 'นครสวรรค์',
        province_code: 60,
        amphoe: 'เมืองนครสวรรค์',
        amphoe_code: 6001,
        zipcode: 60000,
    },
    {
        province: 'นครสวรรค์',
        province_code: 60,
        amphoe: 'โกรกพระ',
        amphoe_code: 6002,
        zipcode: 60170,
    },
    {
        province: 'นครสวรรค์',
        province_code: 60,
        amphoe: 'ชุมแสง',
        amphoe_code: 6003,
        zipcode: 60120,
    },
    {
        province: 'นครสวรรค์',
        province_code: 60,
        amphoe: 'หนองบัว',
        amphoe_code: 6004,
        zipcode: 60110,
    },
    {
        province: 'นครสวรรค์',
        province_code: 60,
        amphoe: 'บรรพตพิสัย',
        amphoe_code: 6005,
        zipcode: 60180,
    },
    {
        province: 'นครสวรรค์',
        province_code: 60,
        amphoe: 'เก้าเลี้ยว',
        amphoe_code: 6006,
        zipcode: 60230,
    },
    {
        province: 'นครสวรรค์',
        province_code: 60,
        amphoe: 'ตาคลี',
        amphoe_code: 6007,
        zipcode: 60260,
    },
    {
        province: 'นครสวรรค์',
        province_code: 60,
        amphoe: 'ท่าตะโก',
        amphoe_code: 6008,
        zipcode: 60160,
    },
    {
        province: 'นครสวรรค์',
        province_code: 60,
        amphoe: 'ไพศาลี',
        amphoe_code: 6009,
        zipcode: 60220,
    },
    {
        province: 'นครสวรรค์',
        province_code: 60,
        amphoe: 'พยุหะคีรี',
        amphoe_code: 6010,
        zipcode: 60130,
    },
    {
        province: 'นครสวรรค์',
        province_code: 60,
        amphoe: 'ลาดยาว',
        amphoe_code: 6011,
        zipcode: 60150,
    },
    {
        province: 'นครสวรรค์',
        province_code: 60,
        amphoe: 'ตากฟ้า',
        amphoe_code: 6012,
        zipcode: 60190,
    },
    {
        province: 'นครสวรรค์',
        province_code: 60,
        amphoe: 'แม่วงก์',
        amphoe_code: 6013,
        zipcode: 60150,
    },
    {
        province: 'นครสวรรค์',
        province_code: 60,
        amphoe: 'แม่เปิน',
        amphoe_code: 6014,
        zipcode: 60150,
    },
    {
        province: 'นครสวรรค์',
        province_code: 60,
        amphoe: 'ชุมตาบง',
        amphoe_code: 6015,
        zipcode: 60150,
    },
    {
        province: 'อุทัยธานี',
        province_code: 61,
        amphoe: 'เมืองอุทัยธานี',
        amphoe_code: 6101,
        zipcode: 61000,
    },
    {
        province: 'อุทัยธานี',
        province_code: 61,
        amphoe: 'ทัพทัน',
        amphoe_code: 6102,
        zipcode: 61120,
    },
    {
        province: 'อุทัยธานี',
        province_code: 61,
        amphoe: 'สว่างอารมณ์',
        amphoe_code: 6103,
        zipcode: 61150,
    },
    {
        province: 'อุทัยธานี',
        province_code: 61,
        amphoe: 'หนองฉาง',
        amphoe_code: 6104,
        zipcode: 61110,
    },
    {
        province: 'อุทัยธานี',
        province_code: 61,
        amphoe: 'หนองขาหย่าง',
        amphoe_code: 6105,
        zipcode: 61130,
    },
    {
        province: 'อุทัยธานี',
        province_code: 61,
        amphoe: 'บ้านไร่',
        amphoe_code: 6106,
        zipcode: 61140,
    },
    {
        province: 'อุทัยธานี',
        province_code: 61,
        amphoe: 'ลานสัก',
        amphoe_code: 6107,
        zipcode: 61160,
    },
    {
        province: 'อุทัยธานี',
        province_code: 61,
        amphoe: 'ห้วยคต',
        amphoe_code: 6108,
        zipcode: 61170,
    },
    {
        province: 'กำแพงเพชร',
        province_code: 62,
        amphoe: 'เมืองกำแพงเพชร',
        amphoe_code: 6201,
        zipcode: 62000,
    },
    {
        province: 'กำแพงเพชร',
        province_code: 62,
        amphoe: 'ไทรงาม',
        amphoe_code: 6202,
        zipcode: 62150,
    },
    {
        province: 'กำแพงเพชร',
        province_code: 62,
        amphoe: 'คลองลาน',
        amphoe_code: 6203,
        zipcode: 62180,
    },
    {
        province: 'กำแพงเพชร',
        province_code: 62,
        amphoe: 'ขาณุวรลักษบุรี',
        amphoe_code: 6204,
        zipcode: 62140,
    },
    {
        province: 'กำแพงเพชร',
        province_code: 62,
        amphoe: 'คลองขลุง',
        amphoe_code: 6205,
        zipcode: 62120,
    },
    {
        province: 'กำแพงเพชร',
        province_code: 62,
        amphoe: 'พรานกระต่าย',
        amphoe_code: 6206,
        zipcode: 62110,
    },
    {
        province: 'กำแพงเพชร',
        province_code: 62,
        amphoe: 'ลานกระบือ',
        amphoe_code: 6207,
        zipcode: 62170,
    },
    {
        province: 'กำแพงเพชร',
        province_code: 62,
        amphoe: 'ทรายทองวัฒนา',
        amphoe_code: 6208,
        zipcode: 62190,
    },
    {
        province: 'กำแพงเพชร',
        province_code: 62,
        amphoe: 'ปางศิลาทอง',
        amphoe_code: 6209,
        zipcode: 62120,
    },
    {
        province: 'กำแพงเพชร',
        province_code: 62,
        amphoe: 'บึงสามัคคี',
        amphoe_code: 6210,
        zipcode: 62210,
    },
    {
        province: 'กำแพงเพชร',
        province_code: 62,
        amphoe: 'โกสัมพีนคร',
        amphoe_code: 6211,
        zipcode: 62000,
    },
    {
        province: 'ตาก',
        province_code: 63,
        amphoe: 'เมืองตาก',
        amphoe_code: 6301,
        zipcode: 63000,
    },
    {
        province: 'ตาก',
        province_code: 63,
        amphoe: 'บ้านตาก',
        amphoe_code: 6302,
        zipcode: 63120,
    },
    {
        province: 'ตาก',
        province_code: 63,
        amphoe: 'สามเงา',
        amphoe_code: 6303,
        zipcode: 63130,
    },
    {
        province: 'ตาก',
        province_code: 63,
        amphoe: 'แม่ระมาด',
        amphoe_code: 6304,
        zipcode: 63140,
    },
    {
        province: 'ตาก',
        province_code: 63,
        amphoe: 'ท่าสองยาง',
        amphoe_code: 6305,
        zipcode: 63150,
    },
    {
        province: 'ตาก',
        province_code: 63,
        amphoe: 'แม่สอด',
        amphoe_code: 6306,
        zipcode: 63110,
    },
    {
        province: 'ตาก',
        province_code: 63,
        amphoe: 'พบพระ',
        amphoe_code: 6307,
        zipcode: 63160,
    },
    {
        province: 'ตาก',
        province_code: 63,
        amphoe: 'อุ้มผาง',
        amphoe_code: 6308,
        zipcode: 63170,
    },
    {
        province: 'ตาก',
        province_code: 63,
        amphoe: 'วังเจ้า',
        amphoe_code: 6309,
        zipcode: 63180,
    },
    {
        province: 'สุโขทัย',
        province_code: 64,
        amphoe: 'เมืองสุโขทัย',
        amphoe_code: 6401,
        zipcode: 64220,
    },
    {
        province: 'สุโขทัย',
        province_code: 64,
        amphoe: 'บ้านด่านลานหอย',
        amphoe_code: 6402,
        zipcode: 64140,
    },
    {
        province: 'สุโขทัย',
        province_code: 64,
        amphoe: 'คีรีมาศ',
        amphoe_code: 6403,
        zipcode: 64160,
    },
    {
        province: 'สุโขทัย',
        province_code: 64,
        amphoe: 'กงไกรลาศ',
        amphoe_code: 6404,
        zipcode: 64170,
    },
    {
        province: 'สุโขทัย',
        province_code: 64,
        amphoe: 'ศรีสัชนาลัย',
        amphoe_code: 6405,
        zipcode: 64130,
    },
    {
        province: 'สุโขทัย',
        province_code: 64,
        amphoe: 'ศรีสำโรง',
        amphoe_code: 6406,
        zipcode: 64120,
    },
    {
        province: 'สุโขทัย',
        province_code: 64,
        amphoe: 'สวรรคโลก',
        amphoe_code: 6407,
        zipcode: 64110,
    },
    {
        province: 'สุโขทัย',
        province_code: 64,
        amphoe: 'ศรีนคร',
        amphoe_code: 6408,
        zipcode: 64180,
    },
    {
        province: 'สุโขทัย',
        province_code: 64,
        amphoe: 'ทุ่งเสลี่ยม',
        amphoe_code: 6409,
        zipcode: 64150,
    },
    {
        province: 'พิษณุโลก',
        province_code: 65,
        amphoe: 'เมืองพิษณุโลก',
        amphoe_code: 6501,
        zipcode: 65230,
    },
    {
        province: 'พิษณุโลก',
        province_code: 65,
        amphoe: 'นครไทย',
        amphoe_code: 6502,
        zipcode: 65120,
    },
    {
        province: 'พิษณุโลก',
        province_code: 65,
        amphoe: 'ชาติตระการ',
        amphoe_code: 6503,
        zipcode: 65170,
    },
    {
        province: 'พิษณุโลก',
        province_code: 65,
        amphoe: 'บางระกำ',
        amphoe_code: 6504,
        zipcode: 65240,
    },
    {
        province: 'พิษณุโลก',
        province_code: 65,
        amphoe: 'บางกระทุ่ม',
        amphoe_code: 6505,
        zipcode: 65110,
    },
    {
        province: 'พิษณุโลก',
        province_code: 65,
        amphoe: 'พรหมพิราม',
        amphoe_code: 6506,
        zipcode: 65180,
    },
    {
        province: 'พิษณุโลก',
        province_code: 65,
        amphoe: 'วัดโบสถ์',
        amphoe_code: 6507,
        zipcode: 65160,
    },
    {
        province: 'พิษณุโลก',
        province_code: 65,
        amphoe: 'วังทอง',
        amphoe_code: 6508,
        zipcode: 65130,
    },
    {
        province: 'พิษณุโลก',
        province_code: 65,
        amphoe: 'เนินมะปราง',
        amphoe_code: 6509,
        zipcode: 65190,
    },
    {
        province: 'พิจิตร',
        province_code: 66,
        amphoe: 'เมืองพิจิตร',
        amphoe_code: 6601,
        zipcode: 66000,
    },
    {
        province: 'พิจิตร',
        province_code: 66,
        amphoe: 'วังทรายพูน',
        amphoe_code: 6602,
        zipcode: 66180,
    },
    {
        province: 'พิจิตร',
        province_code: 66,
        amphoe: 'โพธิ์ประทับช้าง',
        amphoe_code: 6603,
        zipcode: 66190,
    },
    {
        province: 'พิจิตร',
        province_code: 66,
        amphoe: 'ตะพานหิน',
        amphoe_code: 6604,
        zipcode: 66110,
    },
    {
        province: 'พิจิตร',
        province_code: 66,
        amphoe: 'บางมูลนาก',
        amphoe_code: 6605,
        zipcode: 66120,
    },
    {
        province: 'พิจิตร',
        province_code: 66,
        amphoe: 'โพทะเล',
        amphoe_code: 6606,
        zipcode: 66130,
    },
    {
        province: 'พิจิตร',
        province_code: 66,
        amphoe: 'สามง่าม',
        amphoe_code: 6607,
        zipcode: 66220,
    },
    {
        province: 'พิจิตร',
        province_code: 66,
        amphoe: 'ทับคล้อ',
        amphoe_code: 6608,
        zipcode: 66150,
    },
    {
        province: 'พิจิตร',
        province_code: 66,
        amphoe: 'สากเหล็ก',
        amphoe_code: 6609,
        zipcode: 66160,
    },
    {
        province: 'พิจิตร',
        province_code: 66,
        amphoe: 'บึงนาราง',
        amphoe_code: 6610,
        zipcode: 66130,
    },
    {
        province: 'พิจิตร',
        province_code: 66,
        amphoe: 'ดงเจริญ',
        amphoe_code: 6611,
        zipcode: 66210,
    },
    {
        province: 'พิจิตร',
        province_code: 66,
        amphoe: 'วชิรบารมี',
        amphoe_code: 6612,
        zipcode: 66140,
    },
    {
        province: 'เพชรบูรณ์',
        province_code: 67,
        amphoe: 'เมืองเพชรบูรณ์',
        amphoe_code: 6701,
        zipcode: 67000,
    },
    {
        province: 'เพชรบูรณ์',
        province_code: 67,
        amphoe: 'ชนแดน',
        amphoe_code: 6702,
        zipcode: 67150,
    },
    {
        province: 'เพชรบูรณ์',
        province_code: 67,
        amphoe: 'หล่มสัก',
        amphoe_code: 6703,
        zipcode: 67110,
    },
    {
        province: 'เพชรบูรณ์',
        province_code: 67,
        amphoe: 'หล่มเก่า',
        amphoe_code: 6704,
        zipcode: 67120,
    },
    {
        province: 'เพชรบูรณ์',
        province_code: 67,
        amphoe: 'วิเชียรบุรี',
        amphoe_code: 6705,
        zipcode: 67180,
    },
    {
        province: 'เพชรบูรณ์',
        province_code: 67,
        amphoe: 'ศรีเทพ',
        amphoe_code: 6706,
        zipcode: 67170,
    },
    {
        province: 'เพชรบูรณ์',
        province_code: 67,
        amphoe: 'หนองไผ่',
        amphoe_code: 6707,
        zipcode: 67140,
    },
    {
        province: 'เพชรบูรณ์',
        province_code: 67,
        amphoe: 'บึงสามพัน',
        amphoe_code: 6708,
        zipcode: 67160,
    },
    {
        province: 'เพชรบูรณ์',
        province_code: 67,
        amphoe: 'น้ำหนาว',
        amphoe_code: 6709,
        zipcode: 67260,
    },
    {
        province: 'เพชรบูรณ์',
        province_code: 67,
        amphoe: 'วังโป่ง',
        amphoe_code: 6710,
        zipcode: 67240,
    },
    {
        province: 'เพชรบูรณ์',
        province_code: 67,
        amphoe: 'เขาค้อ',
        amphoe_code: 6711,
        zipcode: 67270,
    },
    {
        province: 'ราชบุรี',
        province_code: 70,
        amphoe: 'เมืองราชบุรี',
        amphoe_code: 7001,
        zipcode: 70000,
    },
    {
        province: 'ราชบุรี',
        province_code: 70,
        amphoe: 'จอมบึง',
        amphoe_code: 7002,
        zipcode: 70150,
    },
    {
        province: 'ราชบุรี',
        province_code: 70,
        amphoe: 'สวนผึ้ง',
        amphoe_code: 7003,
        zipcode: 70180,
    },
    {
        province: 'ราชบุรี',
        province_code: 70,
        amphoe: 'ดำเนินสะดวก',
        amphoe_code: 7004,
        zipcode: 70130,
    },
    {
        province: 'ราชบุรี',
        province_code: 70,
        amphoe: 'บ้านโป่ง',
        amphoe_code: 7005,
        zipcode: 70190,
    },
    {
        province: 'ราชบุรี',
        province_code: 70,
        amphoe: 'บางแพ',
        amphoe_code: 7006,
        zipcode: 70160,
    },
    {
        province: 'ราชบุรี',
        province_code: 70,
        amphoe: 'โพธาราม',
        amphoe_code: 7007,
        zipcode: 70120,
    },
    {
        province: 'ราชบุรี',
        province_code: 70,
        amphoe: 'ปากท่อ',
        amphoe_code: 7008,
        zipcode: 70140,
    },
    {
        province: 'ราชบุรี',
        province_code: 70,
        amphoe: 'วัดเพลง',
        amphoe_code: 7009,
        zipcode: 70170,
    },
    {
        province: 'ราชบุรี',
        province_code: 70,
        amphoe: 'บ้านคา',
        amphoe_code: 7010,
        zipcode: 70180,
    },
    {
        province: 'กาญจนบุรี',
        province_code: 71,
        amphoe: 'เมืองกาญจนบุรี',
        amphoe_code: 7101,
        zipcode: 71190,
    },
    {
        province: 'กาญจนบุรี',
        province_code: 71,
        amphoe: 'ไทรโยค',
        amphoe_code: 7102,
        zipcode: 71150,
    },
    {
        province: 'กาญจนบุรี',
        province_code: 71,
        amphoe: 'บ่อพลอย',
        amphoe_code: 7103,
        zipcode: 71160,
    },
    {
        province: 'กาญจนบุรี',
        province_code: 71,
        amphoe: 'ศรีสวัสดิ์',
        amphoe_code: 7104,
        zipcode: 71250,
    },
    {
        province: 'กาญจนบุรี',
        province_code: 71,
        amphoe: 'ท่ามะกา',
        amphoe_code: 7105,
        zipcode: 71120,
    },
    {
        province: 'กาญจนบุรี',
        province_code: 71,
        amphoe: 'ท่าม่วง',
        amphoe_code: 7106,
        zipcode: 71110,
    },
    {
        province: 'กาญจนบุรี',
        province_code: 71,
        amphoe: 'ทองผาภูมิ',
        amphoe_code: 7107,
        zipcode: 71180,
    },
    {
        province: 'กาญจนบุรี',
        province_code: 71,
        amphoe: 'สังขละบุรี',
        amphoe_code: 7108,
        zipcode: 71240,
    },
    {
        province: 'กาญจนบุรี',
        province_code: 71,
        amphoe: 'พนมทวน',
        amphoe_code: 7109,
        zipcode: 71140,
    },
    {
        province: 'กาญจนบุรี',
        province_code: 71,
        amphoe: 'เลาขวัญ',
        amphoe_code: 7110,
        zipcode: 71210,
    },
    {
        province: 'กาญจนบุรี',
        province_code: 71,
        amphoe: 'ด่านมะขามเตี้ย',
        amphoe_code: 7111,
        zipcode: 71260,
    },
    {
        province: 'กาญจนบุรี',
        province_code: 71,
        amphoe: 'หนองปรือ',
        amphoe_code: 7112,
        zipcode: 71220,
    },
    {
        province: 'กาญจนบุรี',
        province_code: 71,
        amphoe: 'ห้วยกระเจา',
        amphoe_code: 7113,
        zipcode: 71170,
    },
    {
        province: 'สุพรรณบุรี',
        province_code: 72,
        amphoe: 'เมืองสุพรรณบุรี',
        amphoe_code: 7201,
        zipcode: 72000,
    },
    {
        province: 'สุพรรณบุรี',
        province_code: 72,
        amphoe: 'เดิมบางนางบวช',
        amphoe_code: 7202,
        zipcode: 72120,
    },
    {
        province: 'สุพรรณบุรี',
        province_code: 72,
        amphoe: 'ด่านช้าง',
        amphoe_code: 7203,
        zipcode: 72180,
    },
    {
        province: 'สุพรรณบุรี',
        province_code: 72,
        amphoe: 'บางปลาม้า',
        amphoe_code: 7204,
        zipcode: 72150,
    },
    {
        province: 'สุพรรณบุรี',
        province_code: 72,
        amphoe: 'ศรีประจันต์',
        amphoe_code: 7205,
        zipcode: 72140,
    },
    {
        province: 'สุพรรณบุรี',
        province_code: 72,
        amphoe: 'ดอนเจดีย์',
        amphoe_code: 7206,
        zipcode: 72170,
    },
    {
        province: 'สุพรรณบุรี',
        province_code: 72,
        amphoe: 'สองพี่น้อง',
        amphoe_code: 7207,
        zipcode: 72110,
    },
    {
        province: 'สุพรรณบุรี',
        province_code: 72,
        amphoe: 'สามชุก',
        amphoe_code: 7208,
        zipcode: 72130,
    },
    {
        province: 'สุพรรณบุรี',
        province_code: 72,
        amphoe: 'อู่ทอง',
        amphoe_code: 7209,
        zipcode: 72160,
    },
    {
        province: 'สุพรรณบุรี',
        province_code: 72,
        amphoe: 'หนองหญ้าไซ',
        amphoe_code: 7210,
        zipcode: 72240,
    },
    {
        province: 'นครปฐม',
        province_code: 73,
        amphoe: 'เมืองนครปฐม',
        amphoe_code: 7301,
        zipcode: 73000,
    },
    {
        province: 'นครปฐม',
        province_code: 73,
        amphoe: 'กำแพงแสน',
        amphoe_code: 7302,
        zipcode: 73180,
    },
    {
        province: 'นครปฐม',
        province_code: 73,
        amphoe: 'นครชัยศรี',
        amphoe_code: 7303,
        zipcode: 73120,
    },
    {
        province: 'นครปฐม',
        province_code: 73,
        amphoe: 'ดอนตูม',
        amphoe_code: 7304,
        zipcode: 73150,
    },
    {
        province: 'นครปฐม',
        province_code: 73,
        amphoe: 'บางเลน',
        amphoe_code: 7305,
        zipcode: 73130,
    },
    {
        province: 'นครปฐม',
        province_code: 73,
        amphoe: 'สามพราน',
        amphoe_code: 7306,
        zipcode: 73220,
    },
    {
        province: 'นครปฐม',
        province_code: 73,
        amphoe: 'พุทธมณฑล',
        amphoe_code: 7307,
        zipcode: 73170,
    },
    {
        province: 'สมุทรสาคร',
        province_code: 74,
        amphoe: 'เมืองสมุทรสาคร',
        amphoe_code: 7401,
        zipcode: 74000,
    },
    {
        province: 'สมุทรสาคร',
        province_code: 74,
        amphoe: 'กระทุ่มแบน',
        amphoe_code: 7402,
        zipcode: 74110,
    },
    {
        province: 'สมุทรสาคร',
        province_code: 74,
        amphoe: 'บ้านแพ้ว',
        amphoe_code: 7403,
        zipcode: 74120,
    },
    {
        province: 'สมุทรสงคราม',
        province_code: 75,
        amphoe: 'เมืองสมุทรสงคราม',
        amphoe_code: 7501,
        zipcode: 75000,
    },
    {
        province: 'สมุทรสงคราม',
        province_code: 75,
        amphoe: 'บางคนที',
        amphoe_code: 7502,
        zipcode: 75120,
    },
    {
        province: 'สมุทรสงคราม',
        province_code: 75,
        amphoe: 'อัมพวา',
        amphoe_code: 7503,
        zipcode: 75110,
    },
    {
        province: 'เพชรบุรี',
        province_code: 76,
        amphoe: 'เมืองเพชรบุรี',
        amphoe_code: 7601,
        zipcode: 76000,
    },
    {
        province: 'เพชรบุรี',
        province_code: 76,
        amphoe: 'เขาย้อย',
        amphoe_code: 7602,
        zipcode: 76140,
    },
    {
        province: 'เพชรบุรี',
        province_code: 76,
        amphoe: 'หนองหญ้าปล้อง',
        amphoe_code: 7603,
        zipcode: 76160,
    },
    {
        province: 'เพชรบุรี',
        province_code: 76,
        amphoe: 'ชะอำ',
        amphoe_code: 7604,
        zipcode: 76120,
    },
    {
        province: 'เพชรบุรี',
        province_code: 76,
        amphoe: 'ท่ายาง',
        amphoe_code: 7605,
        zipcode: 76130,
    },
    {
        province: 'เพชรบุรี',
        province_code: 76,
        amphoe: 'บ้านลาด',
        amphoe_code: 7606,
        zipcode: 76150,
    },
    {
        province: 'เพชรบุรี',
        province_code: 76,
        amphoe: 'บ้านแหลม',
        amphoe_code: 7607,
        zipcode: 76110,
    },
    {
        province: 'เพชรบุรี',
        province_code: 76,
        amphoe: 'แก่งกระจาน',
        amphoe_code: 7608,
        zipcode: 76170,
    },
    {
        province: 'ประจวบคีรีขันธ์',
        province_code: 77,
        amphoe: 'เมืองประจวบคีรีขันธ์',
        amphoe_code: 7701,
        zipcode: 77000,
    },
    {
        province: 'ประจวบคีรีขันธ์',
        province_code: 77,
        amphoe: 'กุยบุรี',
        amphoe_code: 7702,
        zipcode: 77150,
    },
    {
        province: 'ประจวบคีรีขันธ์',
        province_code: 77,
        amphoe: 'ทับสะแก',
        amphoe_code: 7703,
        zipcode: 77130,
    },
    {
        province: 'ประจวบคีรีขันธ์',
        province_code: 77,
        amphoe: 'บางสะพาน',
        amphoe_code: 7704,
        zipcode: 77140,
    },
    {
        province: 'ประจวบคีรีขันธ์',
        province_code: 77,
        amphoe: 'บางสะพานน้อย',
        amphoe_code: 7705,
        zipcode: 77170,
    },
    {
        province: 'ประจวบคีรีขันธ์',
        province_code: 77,
        amphoe: 'ปราณบุรี',
        amphoe_code: 7706,
        zipcode: 77120,
    },
    {
        province: 'ประจวบคีรีขันธ์',
        province_code: 77,
        amphoe: 'หัวหิน',
        amphoe_code: 7707,
        zipcode: 77110,
    },
    {
        province: 'ประจวบคีรีขันธ์',
        province_code: 77,
        amphoe: 'สามร้อยยอด',
        amphoe_code: 7708,
        zipcode: 77180,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'เมืองนครศรีธรรมราช',
        amphoe_code: 8001,
        zipcode: 80280,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'พรหมคีรี',
        amphoe_code: 8002,
        zipcode: 80320,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'ลานสกา',
        amphoe_code: 8003,
        zipcode: 80230,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'ฉวาง',
        amphoe_code: 8004,
        zipcode: 80260,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'พิปูน',
        amphoe_code: 8005,
        zipcode: 80270,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'เชียรใหญ่',
        amphoe_code: 8006,
        zipcode: 80190,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'ชะอวด',
        amphoe_code: 8007,
        zipcode: 80180,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'ท่าศาลา',
        amphoe_code: 8008,
        zipcode: 80160,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'ทุ่งสง',
        amphoe_code: 8009,
        zipcode: 80310,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'นาบอน',
        amphoe_code: 8010,
        zipcode: 80220,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'ทุ่งใหญ่',
        amphoe_code: 8011,
        zipcode: 80240,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'ปากพนัง',
        amphoe_code: 8012,
        zipcode: 80140,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'ร่อนพิบูลย์',
        amphoe_code: 8013,
        zipcode: 80130,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'สิชล',
        amphoe_code: 8014,
        zipcode: 80120,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'ขนอม',
        amphoe_code: 8015,
        zipcode: 80210,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'หัวไทร',
        amphoe_code: 8016,
        zipcode: 80170,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'บางขัน',
        amphoe_code: 8017,
        zipcode: 80360,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'ถ้ำพรรณรา',
        amphoe_code: 8018,
        zipcode: 80260,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'จุฬาภรณ์',
        amphoe_code: 8019,
        zipcode: 80130,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'พระพรหม',
        amphoe_code: 8020,
        zipcode: 80000,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'นบพิตำ',
        amphoe_code: 8021,
        zipcode: 80160,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'ช้างกลาง',
        amphoe_code: 8022,
        zipcode: 80250,
    },
    {
        province: 'นครศรีธรรมราช',
        province_code: 80,
        amphoe: 'เฉลิมพระเกียรติ',
        amphoe_code: 8023,
        zipcode: 80290,
    },
    {
        province: 'กระบี่',
        province_code: 81,
        amphoe: 'เมืองกระบี่',
        amphoe_code: 8101,
        zipcode: 81000,
    },
    {
        province: 'กระบี่',
        province_code: 81,
        amphoe: 'เขาพนม',
        amphoe_code: 8102,
        zipcode: 81140,
    },
    {
        province: 'กระบี่',
        province_code: 81,
        amphoe: 'เกาะลันตา',
        amphoe_code: 8103,
        zipcode: 81120,
    },
    {
        province: 'กระบี่',
        province_code: 81,
        amphoe: 'คลองท่อม',
        amphoe_code: 8104,
        zipcode: 81120,
    },
    {
        province: 'กระบี่',
        province_code: 81,
        amphoe: 'อ่าวลึก',
        amphoe_code: 8105,
        zipcode: 81110,
    },
    {
        province: 'กระบี่',
        province_code: 81,
        amphoe: 'ปลายพระยา',
        amphoe_code: 8106,
        zipcode: 81160,
    },
    {
        province: 'กระบี่',
        province_code: 81,
        amphoe: 'ลำทับ',
        amphoe_code: 8107,
        zipcode: 81190,
    },
    {
        province: 'กระบี่',
        province_code: 81,
        amphoe: 'เหนือคลอง',
        amphoe_code: 8108,
        zipcode: 81130,
    },
    {
        province: 'พังงา',
        province_code: 82,
        amphoe: 'เมืองพังงา',
        amphoe_code: 8201,
        zipcode: 82000,
    },
    {
        province: 'พังงา',
        province_code: 82,
        amphoe: 'เกาะยาว',
        amphoe_code: 8202,
        zipcode: 83000,
    },
    {
        province: 'พังงา',
        province_code: 82,
        amphoe: 'กะปง',
        amphoe_code: 8203,
        zipcode: 82170,
    },
    {
        province: 'พังงา',
        province_code: 82,
        amphoe: 'ตะกั่วทุ่ง',
        amphoe_code: 8204,
        zipcode: 82130,
    },
    {
        province: 'พังงา',
        province_code: 82,
        amphoe: 'ตะกั่วป่า',
        amphoe_code: 8205,
        zipcode: 82220,
    },
    {
        province: 'พังงา',
        province_code: 82,
        amphoe: 'คุระบุรี',
        amphoe_code: 8206,
        zipcode: 82150,
    },
    {
        province: 'พังงา',
        province_code: 82,
        amphoe: 'ทับปุด',
        amphoe_code: 8207,
        zipcode: 82180,
    },
    {
        province: 'พังงา',
        province_code: 82,
        amphoe: 'ท้ายเหมือง',
        amphoe_code: 8208,
        zipcode: 82120,
    },
    {
        province: 'ภูเก็ต',
        province_code: 83,
        amphoe: 'เมืองภูเก็ต',
        amphoe_code: 8301,
        zipcode: 83100,
    },
    {
        province: 'ภูเก็ต',
        province_code: 83,
        amphoe: 'กะทู้',
        amphoe_code: 8302,
        zipcode: 83120,
    },
    {
        province: 'ภูเก็ต',
        province_code: 83,
        amphoe: 'ถลาง',
        amphoe_code: 8303,
        zipcode: 83110,
    },
    {
        province: 'สุราษฎร์ธานี',
        province_code: 84,
        amphoe: 'เมืองสุราษฎร์ธานี',
        amphoe_code: 8401,
        zipcode: 84100,
    },
    {
        province: 'สุราษฎร์ธานี',
        province_code: 84,
        amphoe: 'กาญจนดิษฐ์',
        amphoe_code: 8402,
        zipcode: 84160,
    },
    {
        province: 'สุราษฎร์ธานี',
        province_code: 84,
        amphoe: 'ดอนสัก',
        amphoe_code: 8403,
        zipcode: 84160,
    },
    {
        province: 'สุราษฎร์ธานี',
        province_code: 84,
        amphoe: 'เกาะสมุย',
        amphoe_code: 8404,
        zipcode: 84140,
    },
    {
        province: 'สุราษฎร์ธานี',
        province_code: 84,
        amphoe: 'เกาะพะงัน',
        amphoe_code: 8405,
        zipcode: 84280,
    },
    {
        province: 'สุราษฎร์ธานี',
        province_code: 84,
        amphoe: 'ไชยา',
        amphoe_code: 8406,
        zipcode: 84110,
    },
    {
        province: 'สุราษฎร์ธานี',
        province_code: 84,
        amphoe: 'ท่าชนะ',
        amphoe_code: 8407,
        zipcode: 84170,
    },
    {
        province: 'สุราษฎร์ธานี',
        province_code: 84,
        amphoe: 'คีรีรัฐนิคม',
        amphoe_code: 8408,
        zipcode: 84180,
    },
    {
        province: 'สุราษฎร์ธานี',
        province_code: 84,
        amphoe: 'บ้านตาขุน',
        amphoe_code: 8409,
        zipcode: 84230,
    },
    {
        province: 'สุราษฎร์ธานี',
        province_code: 84,
        amphoe: 'พนม',
        amphoe_code: 8410,
        zipcode: 84250,
    },
    {
        province: 'สุราษฎร์ธานี',
        province_code: 84,
        amphoe: 'ท่าฉาง',
        amphoe_code: 8411,
        zipcode: 84150,
    },
    {
        province: 'สุราษฎร์ธานี',
        province_code: 84,
        amphoe: 'บ้านนาสาร',
        amphoe_code: 8412,
        zipcode: 84120,
    },
    {
        province: 'สุราษฎร์ธานี',
        province_code: 84,
        amphoe: 'บ้านนาเดิม',
        amphoe_code: 8413,
        zipcode: 84240,
    },
    {
        province: 'สุราษฎร์ธานี',
        province_code: 84,
        amphoe: 'เคียนซา',
        amphoe_code: 8414,
        zipcode: 84260,
    },
    {
        province: 'สุราษฎร์ธานี',
        province_code: 84,
        amphoe: 'เวียงสระ',
        amphoe_code: 8415,
        zipcode: 84190,
    },
    {
        province: 'สุราษฎร์ธานี',
        province_code: 84,
        amphoe: 'พระแสง',
        amphoe_code: 8416,
        zipcode: 84210,
    },
    {
        province: 'สุราษฎร์ธานี',
        province_code: 84,
        amphoe: 'พุนพิน',
        amphoe_code: 8417,
        zipcode: 84130,
    },
    {
        province: 'สุราษฎร์ธานี',
        province_code: 84,
        amphoe: 'ชัยบุรี',
        amphoe_code: 8418,
        zipcode: 84350,
    },
    {
        province: 'สุราษฎร์ธานี',
        province_code: 84,
        amphoe: 'วิภาวดี',
        amphoe_code: 8419,
        zipcode: 84180,
    },
    {
        province: 'ระนอง',
        province_code: 85,
        amphoe: 'เมืองระนอง',
        amphoe_code: 8501,
        zipcode: 85130,
    },
    {
        province: 'ระนอง',
        province_code: 85,
        amphoe: 'ละอุ่น',
        amphoe_code: 8502,
        zipcode: 85130,
    },
    {
        province: 'ระนอง',
        province_code: 85,
        amphoe: 'กะเปอร์',
        amphoe_code: 8503,
        zipcode: 85120,
    },
    {
        province: 'ระนอง',
        province_code: 85,
        amphoe: 'กระบุรี',
        amphoe_code: 8504,
        zipcode: 85110,
    },
    {
        province: 'ระนอง',
        province_code: 85,
        amphoe: 'สุขสำราญ',
        amphoe_code: 8505,
        zipcode: 85120,
    },
    {
        province: 'ชุมพร',
        province_code: 86,
        amphoe: 'เมืองชุมพร',
        amphoe_code: 8601,
        zipcode: 86190,
    },
    {
        province: 'ชุมพร',
        province_code: 86,
        amphoe: 'ท่าแซะ',
        amphoe_code: 8602,
        zipcode: 86140,
    },
    {
        province: 'ชุมพร',
        province_code: 86,
        amphoe: 'ปะทิว',
        amphoe_code: 8603,
        zipcode: 86160,
    },
    {
        province: 'ชุมพร',
        province_code: 86,
        amphoe: 'หลังสวน',
        amphoe_code: 8604,
        zipcode: 86110,
    },
    {
        province: 'ชุมพร',
        province_code: 86,
        amphoe: 'ละแม',
        amphoe_code: 8605,
        zipcode: 86170,
    },
    {
        province: 'ชุมพร',
        province_code: 86,
        amphoe: 'พะโต๊ะ',
        amphoe_code: 8606,
        zipcode: 86180,
    },
    {
        province: 'ชุมพร',
        province_code: 86,
        amphoe: 'สวี',
        amphoe_code: 8607,
        zipcode: 86130,
    },
    {
        province: 'ชุมพร',
        province_code: 86,
        amphoe: 'ทุ่งตะโก',
        amphoe_code: 8608,
        zipcode: 86220,
    },
    {
        province: 'สงขลา',
        province_code: 90,
        amphoe: 'เมืองสงขลา',
        amphoe_code: 9001,
        zipcode: 90000,
    },
    {
        province: 'สงขลา',
        province_code: 90,
        amphoe: 'สทิงพระ',
        amphoe_code: 9002,
        zipcode: 90190,
    },
    {
        province: 'สงขลา',
        province_code: 90,
        amphoe: 'จะนะ',
        amphoe_code: 9003,
        zipcode: 90130,
    },
    {
        province: 'สงขลา',
        province_code: 90,
        amphoe: 'นาทวี',
        amphoe_code: 9004,
        zipcode: 90160,
    },
    {
        province: 'สงขลา',
        province_code: 90,
        amphoe: 'เทพา',
        amphoe_code: 9005,
        zipcode: 90150,
    },
    {
        province: 'สงขลา',
        province_code: 90,
        amphoe: 'สะบ้าย้อย',
        amphoe_code: 9006,
        zipcode: 90210,
    },
    {
        province: 'สงขลา',
        province_code: 90,
        amphoe: 'ระโนด',
        amphoe_code: 9007,
        zipcode: 90140,
    },
    {
        province: 'สงขลา',
        province_code: 90,
        amphoe: 'กระแสสินธุ์',
        amphoe_code: 9008,
        zipcode: 90270,
    },
    {
        province: 'สงขลา',
        province_code: 90,
        amphoe: 'รัตภูมิ',
        amphoe_code: 9009,
        zipcode: 90180,
    },
    {
        province: 'สงขลา',
        province_code: 90,
        amphoe: 'สะเดา',
        amphoe_code: 9010,
        zipcode: 90240,
    },
    {
        province: 'สงขลา',
        province_code: 90,
        amphoe: 'หาดใหญ่',
        amphoe_code: 9011,
        zipcode: 90110,
    },
    {
        province: 'สงขลา',
        province_code: 90,
        amphoe: 'นาหม่อม',
        amphoe_code: 9012,
        zipcode: 90310,
    },
    {
        province: 'สงขลา',
        province_code: 90,
        amphoe: 'ควนเนียง',
        amphoe_code: 9013,
        zipcode: 90220,
    },
    {
        province: 'สงขลา',
        province_code: 90,
        amphoe: 'บางกล่ำ',
        amphoe_code: 9014,
        zipcode: 90110,
    },
    {
        province: 'สงขลา',
        province_code: 90,
        amphoe: 'สิงหนคร',
        amphoe_code: 9015,
        zipcode: 90330,
    },
    {
        province: 'สงขลา',
        province_code: 90,
        amphoe: 'คลองหอยโข่ง',
        amphoe_code: 9016,
        zipcode: 90115,
    },
    {
        province: 'สตูล',
        province_code: 91,
        amphoe: 'เมืองสตูล',
        amphoe_code: 9101,
        zipcode: 91000,
    },
    {
        province: 'สตูล',
        province_code: 91,
        amphoe: 'ควนโดน',
        amphoe_code: 9102,
        zipcode: 91160,
    },
    {
        province: 'สตูล',
        province_code: 91,
        amphoe: 'ควนกาหลง',
        amphoe_code: 9103,
        zipcode: 91130,
    },
    {
        province: 'สตูล',
        province_code: 91,
        amphoe: 'ท่าแพ',
        amphoe_code: 9104,
        zipcode: 91150,
    },
    {
        province: 'สตูล',
        province_code: 91,
        amphoe: 'ละงู',
        amphoe_code: 9105,
        zipcode: 91110,
    },
    {
        province: 'สตูล',
        province_code: 91,
        amphoe: 'ทุ่งหว้า',
        amphoe_code: 9106,
        zipcode: 91120,
    },
    {
        province: 'สตูล',
        province_code: 91,
        amphoe: 'มะนัง',
        amphoe_code: 9107,
        zipcode: 91130,
    },
    {
        province: 'ตรัง',
        province_code: 92,
        amphoe: 'เมืองตรัง',
        amphoe_code: 9201,
        zipcode: 92000,
    },
    {
        province: 'ตรัง',
        province_code: 92,
        amphoe: 'กันตัง',
        amphoe_code: 9202,
        zipcode: 92110,
    },
    {
        province: 'ตรัง',
        province_code: 92,
        amphoe: 'ย่านตาขาว',
        amphoe_code: 9203,
        zipcode: 92140,
    },
    {
        province: 'ตรัง',
        province_code: 92,
        amphoe: 'ปะเหลียน',
        amphoe_code: 9204,
        zipcode: 92180,
    },
    {
        province: 'ตรัง',
        province_code: 92,
        amphoe: 'สิเกา',
        amphoe_code: 9205,
        zipcode: 92150,
    },
    {
        province: 'ตรัง',
        province_code: 92,
        amphoe: 'ห้วยยอด',
        amphoe_code: 9206,
        zipcode: 92130,
    },
    {
        province: 'ตรัง',
        province_code: 92,
        amphoe: 'วังวิเศษ',
        amphoe_code: 9207,
        zipcode: 92000,
    },
    {
        province: 'ตรัง',
        province_code: 92,
        amphoe: 'นาโยง',
        amphoe_code: 9208,
        zipcode: 92170,
    },
    {
        province: 'ตรัง',
        province_code: 92,
        amphoe: 'รัษฎา',
        amphoe_code: 9209,
        zipcode: 92160,
    },
    {
        province: 'ตรัง',
        province_code: 92,
        amphoe: 'หาดสำราญ',
        amphoe_code: 9210,
        zipcode: 92120,
    },
    {
        province: 'พัทลุง',
        province_code: 93,
        amphoe: 'เมืองพัทลุง',
        amphoe_code: 9301,
        zipcode: 93000,
    },
    {
        province: 'พัทลุง',
        province_code: 93,
        amphoe: 'กงหรา',
        amphoe_code: 9302,
        zipcode: 93180,
    },
    {
        province: 'พัทลุง',
        province_code: 93,
        amphoe: 'เขาชัยสน',
        amphoe_code: 9303,
        zipcode: 93130,
    },
    {
        province: 'พัทลุง',
        province_code: 93,
        amphoe: 'ตะโหมด',
        amphoe_code: 9304,
        zipcode: 93160,
    },
    {
        province: 'พัทลุง',
        province_code: 93,
        amphoe: 'ควนขนุน',
        amphoe_code: 9305,
        zipcode: 93110,
    },
    {
        province: 'พัทลุง',
        province_code: 93,
        amphoe: 'ปากพะยูน',
        amphoe_code: 9306,
        zipcode: 93120,
    },
    {
        province: 'พัทลุง',
        province_code: 93,
        amphoe: 'ศรีบรรพต',
        amphoe_code: 9307,
        zipcode: 93190,
    },
    {
        province: 'พัทลุง',
        province_code: 93,
        amphoe: 'ป่าบอน',
        amphoe_code: 9308,
        zipcode: 93170,
    },
    {
        province: 'พัทลุง',
        province_code: 93,
        amphoe: 'บางแก้ว',
        amphoe_code: 9309,
        zipcode: 93140,
    },
    {
        province: 'พัทลุง',
        province_code: 93,
        amphoe: 'ป่าพะยอม',
        amphoe_code: 9310,
        zipcode: 93210,
    },
    {
        province: 'พัทลุง',
        province_code: 93,
        amphoe: 'ศรีนครินทร์',
        amphoe_code: 9311,
        zipcode: 93000,
    },
    {
        province: 'ปัตตานี',
        province_code: 94,
        amphoe: 'เมืองปัตตานี',
        amphoe_code: 9401,
        zipcode: 94000,
    },
    {
        province: 'ปัตตานี',
        province_code: 94,
        amphoe: 'โคกโพธิ์',
        amphoe_code: 9402,
        zipcode: 94180,
    },
    {
        province: 'ปัตตานี',
        province_code: 94,
        amphoe: 'หนองจิก',
        amphoe_code: 9403,
        zipcode: 94170,
    },
    {
        province: 'ปัตตานี',
        province_code: 94,
        amphoe: 'ปะนาเระ',
        amphoe_code: 9404,
        zipcode: 94190,
    },
    {
        province: 'ปัตตานี',
        province_code: 94,
        amphoe: 'มายอ',
        amphoe_code: 9405,
        zipcode: 94190,
    },
    {
        province: 'ปัตตานี',
        province_code: 94,
        amphoe: 'ทุ่งยางแดง',
        amphoe_code: 9406,
        zipcode: 94140,
    },
    {
        province: 'ปัตตานี',
        province_code: 94,
        amphoe: 'สายบุรี',
        amphoe_code: 9407,
        zipcode: 94110,
    },
    {
        province: 'ปัตตานี',
        province_code: 94,
        amphoe: 'ไม้แก่น',
        amphoe_code: 9408,
        zipcode: 94220,
    },
    {
        province: 'ปัตตานี',
        province_code: 94,
        amphoe: 'ยะหริ่ง',
        amphoe_code: 9409,
        zipcode: 94150,
    },
    {
        province: 'ปัตตานี',
        province_code: 94,
        amphoe: 'ยะรัง',
        amphoe_code: 9410,
        zipcode: 94160,
    },
    {
        province: 'ปัตตานี',
        province_code: 94,
        amphoe: 'กะพ้อ',
        amphoe_code: 9411,
        zipcode: 94230,
    },
    {
        province: 'ปัตตานี',
        province_code: 94,
        amphoe: 'แม่ลาน',
        amphoe_code: 9412,
        zipcode: 94180,
    },
    {
        province: 'ยะลา',
        province_code: 95,
        amphoe: 'เมืองยะลา',
        amphoe_code: 9501,
        zipcode: 95000,
    },
    {
        province: 'ยะลา',
        province_code: 95,
        amphoe: 'เบตง',
        amphoe_code: 9502,
        zipcode: 95110,
    },
    {
        province: 'ยะลา',
        province_code: 95,
        amphoe: 'บันนังสตา',
        amphoe_code: 9503,
        zipcode: 95130,
    },
    {
        province: 'ยะลา',
        province_code: 95,
        amphoe: 'ธารโต',
        amphoe_code: 9504,
        zipcode: 95150,
    },
    {
        province: 'ยะลา',
        province_code: 95,
        amphoe: 'ยะหา',
        amphoe_code: 9505,
        zipcode: 95120,
    },
    {
        province: 'ยะลา',
        province_code: 95,
        amphoe: 'รามัน',
        amphoe_code: 9506,
        zipcode: 95140,
    },
    {
        province: 'ยะลา',
        province_code: 95,
        amphoe: 'กาบัง',
        amphoe_code: 9507,
        zipcode: 95120,
    },
    {
        province: 'ยะลา',
        province_code: 95,
        amphoe: 'กรงปินัง',
        amphoe_code: 9508,
        zipcode: 95000,
    },
    {
        province: 'นราธิวาส',
        province_code: 96,
        amphoe: 'เมืองนราธิวาส',
        amphoe_code: 9601,
        zipcode: 96000,
    },
    {
        province: 'นราธิวาส',
        province_code: 96,
        amphoe: 'ตากใบ',
        amphoe_code: 9602,
        zipcode: 96110,
    },
    {
        province: 'นราธิวาส',
        province_code: 96,
        amphoe: 'บาเจาะ',
        amphoe_code: 9603,
        zipcode: 96170,
    },
    {
        province: 'นราธิวาส',
        province_code: 96,
        amphoe: 'ยี่งอ',
        amphoe_code: 9604,
        zipcode: 96180,
    },
    {
        province: 'นราธิวาส',
        province_code: 96,
        amphoe: 'ระแงะ',
        amphoe_code: 9605,
        zipcode: 96130,
    },
    {
        province: 'นราธิวาส',
        province_code: 96,
        amphoe: 'รือเสาะ',
        amphoe_code: 9606,
        zipcode: 96150,
    },
    {
        province: 'นราธิวาส',
        province_code: 96,
        amphoe: 'ศรีสาคร',
        amphoe_code: 9607,
        zipcode: 96210,
    },
    {
        province: 'นราธิวาส',
        province_code: 96,
        amphoe: 'แว้ง',
        amphoe_code: 9608,
        zipcode: 96160,
    },
    {
        province: 'นราธิวาส',
        province_code: 96,
        amphoe: 'สุคิริน',
        amphoe_code: 9609,
        zipcode: 96190,
    },
    {
        province: 'นราธิวาส',
        province_code: 96,
        amphoe: 'สุไหงโก-ลก',
        amphoe_code: 9610,
        zipcode: 96120,
    },
    {
        province: 'นราธิวาส',
        province_code: 96,
        amphoe: 'สุไหงปาดี',
        amphoe_code: 9611,
        zipcode: 96140,
    },
    {
        province: 'นราธิวาส',
        province_code: 96,
        amphoe: 'จะแนะ',
        amphoe_code: 9612,
        zipcode: 96220,
    },
    {
        province: 'นราธิวาส',
        province_code: 96,
        amphoe: 'เจาะไอร้อง',
        amphoe_code: 9613,
        zipcode: 96130,
    },
    {
        province: 'บึงกาฬ',
        province_code: 97,
        amphoe: 'บึงโขงหลง',
        amphoe_code: false,
        zipcode: 38220,
    },
]